import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import '@fontsource/ibm-plex-sans';
import Amplify from 'aws-amplify';
import { initializeApp } from 'firebase/app';
 import { getMessaging, onMessage,getToken } from 'firebase/messaging';
import 'focus-visible/dist/focus-visible';
import React,{useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import App from './app';
import amplifyConfig from './aws-exports';
import theme from './components/common/theme';
import { register } from './register-sw';
import reportWebVitals from './reportWebVitals';

import store from './stores';
import { setupI18n } from './utils/i18n';


Amplify.configure(amplifyConfig);

setupI18n();

ReactDOM.render(
  <React.StrictMode> 
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </Provider>
      </ChakraProvider> 
  </React.StrictMode>,
  document.getElementById('root')
);

// register service worker
  register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 reportWebVitals();

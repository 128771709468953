import { Box, Flex, useColorMode } from '@chakra-ui/react';
import React from 'react';
import Footer from '../common/footer';
import TopBar from '../common/topbar';

export function Layout({ children,orderValue }) {
  const {colorMode}= useColorMode()
  const currentUrl = window.location.href;
console.log('currentUrl',currentUrl);
  return (
    <Box>
      <Flex flexDirection="column">
        {/* <Sidebar /> */}
        <Box background={colorMode === "light"?"white":"black"} w="100%" minH="calc(100vh - 85px)">
          {currentUrl.includes('thank-you')? <></>:<TopBar />}
          
          <Box background="white">
            {children}
          </Box>
        </Box>
        <Box>
        {currentUrl.includes('thank-you')? <></>: <Footer height="85px"/>}
        </Box>
      </Flex>
    </Box>
  );
}

export const noop = 'value';

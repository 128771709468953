import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
  } from '@chakra-ui/react';
  import React from 'react';
  
  const UpdateStripeNotificationView = (props) => {
    const { updatedStripeOrderDetails, isUpdateStripeOrderOpen, onUpdateStripeOrderClose } = props;
   
   console.log("UpdatedOrderDetails:",updatedStripeOrderDetails,parseFloat(updatedStripeOrderDetails.amount_received),updatedStripeOrderDetails.amount_received !== "0" && (parseFloat(updatedStripeOrderDetails.amount_received) < parseFloat(updatedStripeOrderDetails.amount_capturable)));
  
    const cancelRef = React.useRef();
  
    return (
      <AlertDialog
        isOpen={isUpdateStripeOrderOpen}
        leastDestructiveRef={cancelRef}
        onClose={onUpdateStripeOrderClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader color="White" fontSize="lg" bg="brand.red">
              Order Status
            </AlertDialogHeader>
            <AlertDialogCloseButton color="White" />
            <AlertDialogBody textAlign="center">
            {updatedStripeOrderDetails.stripe_event_type==="payment_intent.amount_capturable_updated" ? (
    <Box color="brand.red" mt="3">
        Your Payment of <b>$ { parseFloat(updatedStripeOrderDetails.amount_capturable)/100}</b> is successful.
    </Box>
) : updatedStripeOrderDetails.stripe_event_type==="payment_intent.succeeded" && updatedStripeOrderDetails.amount_received !== "0" && (parseFloat(updatedStripeOrderDetails.amount_received) < parseFloat(updatedStripeOrderDetails.amount_capturable)) ? (
  <Box color="brand.red" mt="3">
      ${ parseFloat(updatedStripeOrderDetails.amount_received)/100} is charged for the return doordash order for customer not showing up
  </Box>
) : updatedStripeOrderDetails.deliverytype==="Delivery" ? (
    <Box color="brand.red" mt="3">
        Your order is delivered
    </Box>
) : updatedStripeOrderDetails.deliverytype !=="Delivery" ? (
    <Box color="brand.red" mt="3">
        Your order is picked
    </Box>
) : (null)}

              
              {/* <Box mt="4">
                You can always track your orders in the "My Orders List" section
                under the my profile.
              </Box> */}
            </AlertDialogBody>
            <AlertDialogFooter sx={{ margin: 'auto' }}>
              <Button
                ref={cancelRef}
                onClick={onUpdateStripeOrderClose}
                variant="cancel-button"
              >
                Okay
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };
  
  
  export default UpdateStripeNotificationView;
  
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
} from '@chakra-ui/react';
import React,{useEffect} from 'react';
import audio2 from "../../../assets/home/audio2.mp3";


const UpdateNotificationView = (props) => {
  const { updatedOrderDetails, isUpdateOrderOpen, onUpdateOrderClose } = props;
  useEffect(() => {
    if (updatedOrderDetails && updatedOrderDetails.shipmentStatus) {
      // Play notification sound when order is updated
      const successAudio = new Audio(audio2);
      successAudio.play().catch((err) => console.error('Audio play error:', err));
    }
  }, [updatedOrderDetails]);
 
 // console.log("UpdatedOrderDetails:",updatedOrderDetails);

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isUpdateOrderOpen}
      leastDestructiveRef={cancelRef}
      onClose={onUpdateOrderClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader color="White" fontSize="lg" bg="brand.red">
            Order Updated
          </AlertDialogHeader>
          <AlertDialogCloseButton color="White" />
          <AlertDialogBody textAlign="center">
            <Box color="brand.red" mt="3">
              Your order <b>{updatedOrderDetails.orderId}</b> is {updatedOrderDetails.shipmentStatus}!
            </Box>
            <Box mt="4">
              You can always track your orders in the "My Orders List" section
              under the my profile.
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter sx={{ margin: 'auto' }}>
            <Button
              ref={cancelRef}
              onClick={onUpdateOrderClose}
              variant="cancel-button"
            >
              Okay
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};


export default UpdateNotificationView;

export const signInPage = {
  signin: ' Customer Sign In',
  userId: 'User Id',
  mobileNumber: 'Mobile Number',
  emailId: 'Email Id',
  passwordDesc: 'Password',
  phoneNumber: 'Mobile Number',
  signup: 'Sign up',
  forgotPassword: 'Forgot  your Password?',
  login: 'Sign In',
};

export const signUpPage = {
  signUp: 'Sign Up',
  accountSetup: 'Account Setup',
  userId: 'User Id',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  emailId: 'Email',
  passwordDesc: 'Password',
  phoneNumber: 'Mobile Number',
  confirmPassword: 'Confirm Password',
  backToLogin: 'Back to Login',
  next: 'Next',
  verfication: 'Verify Mobile',
  mobileVerification: 'Please enter the 6-digit  Verification code that was sent to your mobile.',
  // verificationCodeText: 'Verification code sent to your Mobile',
  resentOTP: 'Re-send',
};

export default { signInPage, signUpPage };

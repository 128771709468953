/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  useToast,
  InputGroup,useColorMode
} from '@chakra-ui/react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { signUpPage } from '../../utils/resources-en';
import { inputTextStyleProps } from '../../utils/stylesProps';
import { updateConfirmSignup } from '../../graphql/mutations';
import awsExports from '../../aws-exports';

const { verfication, mobileVerification, verificationCodeText, resentOTP } =
  signUpPage;

const VerifyForm = (props) => {
  const toast = useToast();
  const colorMode = useColorMode();
  const { switchToSignIn, username, phoneNumberValue, verifyFromSignIn } =
    props;
  const [phoneNum, setPhoneNum] = useState(phoneNumberValue);
  const [resentOTP, setResentOTP] = useState('Resend Code');
  const [showResendHeading, setShowResendHeading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      verificationCode1: '',
      verificationCode2: '',
      verificationCode3: '',
      verificationCode4: '',
      verificationCode5: '',
      verificationCode6: '',
    },
    onSubmit: async (values) => {
      try {
        setVerifyLoading(true);
        await Auth.confirmSignUp(username, Object.values(values).join(''),);
        const confirmSignupResp = graphqlOperation(updateConfirmSignup, {input: {
          username,
        }});
        API.graphql({
          ...confirmSignupResp,
          authMode: 'API_KEY',
          authToken: awsExports.aws_appsync_apiKey,
        });
        toast({
          title: 'Successfully verified your mobile',
          status: 'success',
          description: 'Sign in to get started',
        });
        setTimeout(() => {
          setVerifyLoading(false);
          switchToSignIn();
        }, 1000);
      } catch (error) {
        setVerifyLoading(false);
        toast({
          title: 'Something went wrong in verifying ',
          description: 'Please try again',
          status: 'error',
        });
        // setSignupState("signup")
      }
    },
  });

  const { values, handleBlur, handleSubmit,  handleChange, resetForm, setFieldValue, setValues,} = formik;

  const [counter, setCounter] = useState(59);

  const verifyInputRefs = Array.from({ length: 7 }).map(
    (_, index) => React.createRef()
  );

 const handleInputKeyDown = (index, e) => {
  const currentInputRef = verifyInputRefs[index];
  const nextIndex = index + 1;
  const nextInputRef = verifyInputRefs[nextIndex];


  if (e.key === "Backspace") {
    console.log('setVerifyValues111',values);
    // Clear the current input box when Backspace is pressed
   

    if (values[`verificationCode${index}`]) {
      // Move focus to the previous input box when Backspace is pressed and the current box is empty
      currentInputRef.current.focus();
    }
    else if(index > 1) {
      // Move focus to the previous input box when Backspace is pressed and the current box is empty
      verifyInputRefs[index-1].current.focus();
    }
  } else if (e.key.match(/[0-9]/)) {
    // Move focus to the next input box when a digit is pressed and it's not the last box
    if (values[`verificationCode${index}`]) {
      nextInputRef.current.focus();
    } else {
      currentInputRef.current.focus();
    }
  }
};
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendVerifyCode = useCallback(async () => {
    if (username) {
      try {
        const res = await Auth.resendSignUp(username);
        setCounter(59);
        setPhoneNum(res?.CodeDeliveryDetails?.Destination);
        setResentOTP('Code Resent'); // Update button text
        setShowResendHeading(true); 
        toast({
          title: 'Successfully resent code to your mobile',
          status: 'success',
          description: 'Sign in to get started',
        });
      } catch (error) {
        if (error.code === 'UserNotFoundException') {
          // setIsSigningIn(false);
        }
        toast({
          title: 'Error',
          description:
            error?.message || 'Something went wrong. Please try again',
        });
      }
    }
  }, [username, toast]);

  useEffect(() => {
    if (verifyFromSignIn === 'true') {
      resendVerifyCode();
    }
  }, [resendVerifyCode, verifyFromSignIn]);
  const areAllBoxesFilled = Object.values(values).every((code) => code.trim() !== '');
  return (
    <Stack px={12} >
      <form onSubmit={handleSubmit} width='529px' height='398.87px'>
        <Stack>
        {!showResendHeading && <Heading fontSize="22px" mt={10}>{verfication}</Heading>}
        {showResendHeading && <Heading fontSize="22px" mt={10}>Code Resent</Heading>}
        
        </Stack>
        <Stack direction="column" pb="10">
          {/* <Text
            alignItems="initial"
            color="brand.red"
          >{`${verificationCodeText} ${phoneNum}`}</Text> */}
          <FormControl id="code">
            {!showResendHeading  && <FormLabel fontSize={16} fontWeight={400} mt={10}>{mobileVerification}</FormLabel>}
            {showResendHeading  && <FormLabel fontSize={16} fontWeight={400} mt={10}>{`We just sent a text message with a new verification code to MobileNumber ${phoneNum}`}</FormLabel>}
            {/* <Input
              variant="filled"
              value={values.code}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue('code', e.target.value);
              }}
              placeholder="Please enter OTP"
              _placeholder={{color:"grey"}}
            /> */}
            <InputGroup marginTop={5}>
        {[1, 2, 3, 4, 5, 6,].map((index) => (
          <Input
            key={index}
            variant="filled"
            ref={verifyInputRefs[index]}
            name={`verificationCode${index}`}
            value={values[`verificationCode${index}`]}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={(e) => handleInputKeyDown(index,e)}
            maxLength="1"
            textAlign="center"
            textColor={ colorMode === 'light' ? 'black' : 'black'}
            // rounded="md"
            style={{borderRadius:'13px'}}
            height='50px'
            width='65.93px'
            borderWidth="0.5px"
            margin='5px'
            _focus={{
              borderColor: "blue.500",
              boxShadow: "0 0 0 2px rgba(0, 0, 255, 0.2)",
            }}
          />
        ))}
      </InputGroup>
          </FormControl>
          <Stack spacing={4}>
            <Stack align="center" justify="space-between">
              <HStack>
                <Text color="#B72618" fontSize={12} fontWeight={500}>00:{counter}</Text>
                <Text fontSize={12} fontWeight={500}>Didn't receive a message? </Text>
                <Button
                  color="#B72618"
                  fontSize={12}
                  fontWeight={500}
                  variant="link"
                  disabled={counter !== 0}
                  onClick={() => resendVerifyCode()}
                >
                  {resentOTP}
                </Button>
              </HStack>
            </Stack>
            <Flex pb="15">
              <Box style={{marginTop:10}}>
                <Button
                  variant="cancel-button"
                  borderColor="brand.red"
                  color="brand.red"
                  onClick={() => switchToSignIn()}
                >
                  Back to Login
                </Button>
              </Box>
              <Spacer />
              <Box style={{marginTop:10}}>
                <Button
                 isDisabled={!areAllBoxesFilled}
                  isLoading={verifyLoading}
                  type="submit"
                  _hover={{ background: 'brand.red' }}
                  data-cy="verifyBtn"
                >
                  Verify
                </Button>
              </Box>
            </Flex>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

const stateMapper = () => ({});

const dispatchMapper = (dispatch) => ({
  signup: dispatch.auth.signUp,
});

export default connect(stateMapper, dispatchMapper)(VerifyForm);

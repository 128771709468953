import { API, graphqlOperation } from 'aws-amplify';
import awsExports from '../../aws-exports';
import {
  getCart as getCartQuery,
  getCartByUserId as getCartByUserIdQuery,
} from '../../graphql/queries';
import  {updateCart as updateCartQuery1}  from '../../graphql/mutations';

const cartStore = {
  state: {
    cart: null,
  },
  reducers: {
    UPDATE_CART(state, cart) {
      return {
        ...state,
        cart,
      };
    },
    EMPTY_CART(state) {
      return {
        ...state,
        cart: null,
        cartInvalidFlag: false,
      };
    },
    SET_CART_INVALID_FLAG(state, cartInvalidFlag) {
      return {
        ...state,
        cartInvalidFlag,
      };
    },
  },
  effects: (dispatch) => ({
    updateCart(cart) {
      dispatch.cart.UPDATE_CART(cart);
    },
    emptyCart(cart) {
      dispatch.cart.EMPTY_CART(cart);
      dispatch.cart.SET_CART_INVALID_FLAG(false);
    },
    setCartInvalidFlag(flag) {
      dispatch.cart.SET_CART_INVALID_FLAG(flag);
    },
    async fetchAndSetCart(userId) {
      try {
        const graphqlQuery = graphqlOperation(getCartByUserIdQuery, {
          userId,
        });

        const res = await API.graphql({
          ...graphqlQuery,
          authMode: 'API_KEY',
          authToken: awsExports.aws_appsync_apiKey,
        });
        // console.log('cart:::',localStorage.getItem('persist:root'));
        
          const id1= JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.cart)?.cart?.id
          const localcart = JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.cart)?.cart;
          console.log('iddd',id1,localcart,JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.cart)?.cart);
        if (res.data.getCartByUserId.items.length > 0) {
          const graphqlQuery1 = graphqlOperation(getCartQuery, {
            id: res.data.getCartByUserId.items.pop().id,
          });
          const res1 = await API.graphql({
            ...graphqlQuery1,
            authMode: 'API_KEY',
            authToken: awsExports.aws_appsync_apiKey,
          });
          const cartData = res1.data.getCart;
        if(id1!==undefined && localcart.cartShipment.items.length>0 ){
          const graphqlQuery2 = graphqlOperation(getCartQuery, {
            id: id1,
          });
          const res2 = await API.graphql({
            ...graphqlQuery2,
            authMode: 'API_KEY',
            authToken: awsExports.aws_appsync_apiKey,
          });
          const cartData1 = res2.data.getCart;
          console.log('cartdata1',cartData1);
           cartData1.id = cartData.id;
          // cartData.userId = cartData.userId;
          for (let j in cartData1.cartShipment.items){
            const i = cartData.cartShipment.items.findIndex(
              (eachPartialItem) => eachPartialItem.assignedStoreId === cartData1.cartShipment.items[j].assignedStoreId,
            );
            console.log("index of CartShipments:",i);

            if (i==-1){
              console.log('shipment levl');

              cartData1.cartShipment.items[j].cartId=cartData.id;
              cartData1.cartShipment.items[j].userId=cartData.userId;
              cartData1.cartShipment.items[j].updatedBy = cartData.userId
              cartData.cartShipment.items.push(
                cartData1.cartShipment.items[j]
                );
            }
            else if(i>-1){
              for(let l in cartData1.cartShipment.items[j].lineItems){
                const li = cartData.cartShipment.items[i].lineItems.findIndex(
                  (eachLineItem) => eachLineItem.productId === cartData1.cartShipment.items[j].lineItems[l].productId,
                );
                console.log("index of lineItems:",li);
                    if(li==-1){
                 cartData.cartShipment.items[i].lineItems.push(cartData1.cartShipment.items[j].lineItems[l]);
                      console.log('lineitem levl');
               }
               else if(li>-1){
              if(cartData.cartShipment.items[i].lineItems[li].qtyPurchased < cartData1.cartShipment.items[j].lineItems[l].qtyPurchased)
                   cartData.cartShipment.items[i].lineItems[li]= cartData1.cartShipment.items[j].lineItems[l];
               }  
              }
            }
        }
        
    let test12 = JSON.parse(JSON.stringify(cartData));
    console.log('test12',test12);
        
      let sameCartShipment = JSON.parse(JSON.stringify(test12.cartShipment.items));
      console.log("SameCartShipment 163",sameCartShipment);
     
      for (let a in sameCartShipment){
        sameCartShipment[a].actionType='update';
        // delete sameCartShipment[a].cartId;
        sameCartShipment[a].cartId=test12.id;
        sameCartShipment[a].userId=userId;
      }
     for (let ii in sameCartShipment){
      for (let jj in sameCartShipment[ii].lineItems){
        delete sameCartShipment[ii].lineItems[jj].id;
        delete sameCartShipment[ii].lineItems[jj].qtySelected;
        delete sameCartShipment[ii].lineItems[jj].updateBtnDisabled;
      }
     }
      test12.cartShipment=sameCartShipment;
      // console.log('graphQuery::',tempCart);
      console.log('graphQuery1::',test12);
      const res123 = await API.graphql(graphqlOperation(updateCartQuery1, { input: test12}))
          }

          if (cartData !== null) {
            cartData.cartShipment.items.forEach((eachCartShipment) => {
              eachCartShipment.lineItems.forEach((li) => {
                li.unitPrice = parseFloat(li.unitPrice).toFixed(2) * 1;
                li.totalPrice = parseFloat(li.totalPrice).toFixed(2) * 1;
              });
            });
          }

          dispatch.cart.UPDATE_CART({
            ...cartData,
          });
          return cartData;
        }
      
        return null;
      
      } catch (error) {
        console.log('fetchAndSetCart', error);
      }
    },
  }),
};

export default cartStore;

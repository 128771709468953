/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  livemode: 'yes',
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:024742c9-526e-4996-b291-bcbf534ec917",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_FRpH91ZbT",
  aws_user_pools_web_client_id: "1n4k4i3m4o3gj227irg79njjb7",
  aws_appsync_graphqlEndpoint:
    "https://p4pktstawjbprpf66gl5dppx3y.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_redirect: 'https://producer.1800spirits.com/',
  aws_merchantredirect: 'https://merchant.1800spirits.com/',
  aws_promtionalredirect: 'https://promotional.1800spirits.com/',
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-ftrkvvzp5vecvks6hggtwnqvbu",
  aws_geo_mapName: "spirits-prd-platform-Here-map",
  aws_geo_mapIndexName: "spirits-prd-platform-Here-singleusePlaceindex",
  aws_s3_imagesUrl: "https://d1ikglaeczx2h4.cloudfront.net",
  // firebase_vapidKey:
  //   "BO7qQGfsWgtkIWL63bK23X5Q-SVNPYwS0SWs1ycEhnwsTZyNhxHgm1NY9YeZVeXUBT4rB_EJzJKdIuxb_GcWdUs",
  firebase_vapidKey:
    'BNY43oSvewdqMQNTiyT0nK_jtaQIiLeGph0B26CMDuDjpGu3CDaVP1OA8hXj4h8wC6bLzCs-KG4n7xMYBxPYNlY',
  stripe_key:
    "pk_live_51KWLzaKSMMiABBtCOKKlj5C2i5BdViKoTiNSoi2GBl2GxvjspIMqwOL80Zhh0kgcA4C21yKSF7xgW7d04IXwKVg100u4I7838u",
  oauth: {
    domain: "1800spirits-customer-prd.auth.us-east-1.amazoncognito.com",
    scope: [
      "email",
      "openid",
      "profile"
    ],
    redirectSignIn: "https://www.1800spirits.com/signin/",
    redirectSignOut: "https://www.1800spirits.com/signout/",
    responseType: "token",
  },
  federationTarget: "COGNITO_USER_POOLS",
}

export default awsmobile;
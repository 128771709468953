import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  VisuallyHidden,
  MenuGroup,
  MenuDivider,
  useColorMode,
} from '@chakra-ui/react';
import { navigate, useLocation } from '@reach/router';
import React, { useState, memo, useEffect } from 'react';
import { BsPencil } from 'react-icons/bs';
import { GiPriceTag } from 'react-icons/gi';
import { connect } from 'react-redux';
import DeliveryTo from '../../../layouts/delivery-to';
import Location from 'aws-sdk/clients/location';
import DeliveryAddressSelectorModal from '../../home/deliveryAddressSelectorModal';
import { Auth } from 'aws-amplify';
import awsExports from '../../../aws-exports';

const navigation = [
  {
    navItem: 'Beer',
    navLinks: [
      {
        title: 'Beer Varieties',
        links: [
          { name: 'Alternative', url: '/category/Beer/Alternative' },
          { name: 'Malt', url: '/category/Beer/Malt' },
          { name: 'Flavored', url: '/category/Beer/Flavored' },
          { name: 'Gluten Free', url: '/category/Beer/Gluten Free' },
          { name: 'Cider', url: '/category/Beer/Cider' },
          { name: 'Seltzer', url: '/category/Beer/Seltzer' },
          { name: 'Imported', url: '/category/Beer/Imported' },
          { name: 'Ale', url: '/category/Beer/Ale' },
          { name: 'Low Alcohol', url: '/category/Beer/Low Alcohol' },
          { name: 'Craft', url: '/category/Beer/Craft' },
          { name: 'Non-Alcoholic', url: '/category/Beer/Non-Alcoholic' },
          { name: 'Premium Beer', url: '/category/Beer/Premium Beer' },
          { name: 'Light Beer', url: '/category/Beer/Light Beer' },
        ],
      },
    ],
  },
  {
    navItem: 'Wine',
    navLinks: [
      {
        title: 'Wine Categories',
        links: [
          { name: 'Red', url: '/category/Wine/Red' },
          { name: 'White', url: '/category/Wine/White' },
          { name: 'Sparkling Wine', url: '/category/Wine/Sparkling' },
          { name: 'Wine Cocktail', url: '/category/Wine/Cocktail' },
          { name: 'Rose', url: '/category/Wine/Rose' },
          { name: 'Flavored Wine', url: '/category/Wine/Flavored' },
          { name: 'Carbonated Wine', url: '/category/Wine/Carbonated' },
          { name: 'Blush Wine', url: '/category/Wine/Blush' },
          { name: 'White - Red Wine', url: '/category/Wine/White - Red' },
          { name: 'Gift Set', url: '/category/Wine/Gift Set' },
          { name: 'Ice Wine', url: '/category/Wine/Ice' },
          { name: 'Mead', url: '/category/Wine/Mead' },
          { name: 'Port Wine', url: '/category/Wine/Port' },
          { name: 'Rice Wine', url: '/category/Wine/Rice' },
          { name: 'Fruit Wine', url: '/category/Wine/Fruit' },
          { name: 'Sherry Wine', url: '/category/Wine/Sherry' },
          { name: 'Tonic Wine', url: '/category/Wine/Tonic' },
          // { name: 'Alcohol Free', url: '/category/Wine/Alcohol Free' },
          { name: 'Wine Spritzer', url: '/category/Wine/Spritzer' },
        ],
      },
      // {
      //   title: 'Wine Varieties',
      //   links: [
      //     { name: 'Red', url: '/category/Wine/Red' },
      //     { name: 'White', url: '/category/Wine/White' },
      //     { name: 'Sparkling Wine', url: '/category/Wine/Sparkling Wine' },
      //     { name: 'Wine Cocktail', url: '/category/Wine/Wine Cocktail' },
      //     { name: 'Rose', url: '/category/Wine/Rose' },
      //     { name: 'Flavored Wine', url: '/category/Wine/Flavored Wine' },
      //     { name: 'Carbonated Wine', url: '/category/Wine/Carbonated Wine' },
      //     { name: 'Blush Wine', url: '/category/Wine/Blush Wine' },
      //     // { name: 'White - Red Wine', url: '/category/Wine/White - Red Wine' },
      //     // { name: 'Gift Set', url: '/category/Wine/Gift Set' },
      //     // { name: 'Ice Wine', url: '/category/Wine/Ice Wine' },
      //     // { name: 'Mead', url: '/category/Wine/Mead' },
      //     // { name: 'Port Wine', url: '/category/Wine/Port Wine' },
      //     // { name: 'Rice Wine', url: '/category/Wine/Rice Wine' },
      //     // { name: 'Fruit Wine', url: '/category/Wine/Fruit Wine' },
      //     // { name: 'Sherry Wine', url: '/category/Wine/Sherry Wine' },
      //     // { name: 'Tonic Wine', url: '/category/Wine/Tonic Wine' },
      //     // { name: 'Alcohol Free', url: '/category/Wine/Alcohol Free' },
      //     // { name: 'Wine Spritzer', url: '/category/Wine/Wine Spritzer' },
      //   ],
      // },
      // {
      //   title: 'Wine Variety Blends',
      //   links: [
      //     { name: 'Red', url: '/category/Wine/Red' },
      //     { name: 'White', url: '/category/Wine/White' },
      //     { name: 'Sparkling Wine', url: '/category/Wine/Sparkling Wine' },
      //     { name: 'Wine Cocktail', url: '/category/Wine/Wine Cocktail' },
      //     { name: 'Rose', url: '/category/Wine/Rose' },
      //     { name: 'Flavored Wine', url: '/category/Wine/Flavored Wine' },
      //     { name: 'Carbonated Wine', url: '/category/Wine/Carbonated Wine' },
      //     { name: 'Blush Wine', url: '/category/Wine/Blush Wine' },
      //     // { name: 'White - Red Wine', url: '/category/Wine/White - Red Wine' },
      //     // { name: 'Gift Set', url: '/category/Wine/Gift Set' },
      //     // { name: 'Ice Wine', url: '/category/Wine/Ice Wine' },
      //     // { name: 'Mead', url: '/category/Wine/Mead' },
      //     // { name: 'Port Wine', url: '/category/Wine/Port Wine' },
      //     // { name: 'Rice Wine', url: '/category/Wine/Rice Wine' },
      //     // { name: 'Fruit Wine', url: '/category/Wine/Fruit Wine' },
      //     // { name: 'Sherry Wine', url: '/category/Wine/Sherry Wine' },
      //     // { name: 'Tonic Wine', url: '/category/Wine/Tonic Wine' },
      //     // { name: 'Alcohol Free', url: '/category/Wine/Alcohol Free' },
      //     // { name: 'Wine Spritzer', url: '/category/Wine/Wine Spritzer' },
      //   ],
      // },
    ],
  },
  {
    navItem: 'Liquor',
    navLinks: [
      {
        title: 'Liquor Varieties',
        links: [
          { name: 'Whiskey', url: '/category/Liquor/Whiskey' },
          { name: 'Brandy', url: '/category/Liquor/Brandy' },
          { name: 'Vodka', url: '/category/Liquor/Vodka' },
          { name: 'Gin', url: '/category/Liquor/Gin' },
          { name: 'Rum', url: '/category/Liquor/Rum' },
          { name: 'Tequila', url: '/category/Liquor/Tequila' },
          { name: 'Cocktails', url: '/category/Liquor/Cocktails' },
          { name: 'Non Alcoholic', url: '/category/Liquor/Non Alcoholic' },
          { name: 'Asian Spirits', url: '/category/Liquor/Asian Spirits' },
          {
            name: 'Distilled Spirits',
            url: '/category/Liquor/Distilled Spirits',
          },
          { name: 'Mezcal', url: '/category/Liquor/Mezcal' },
          { name: 'Moonshine', url: '/category/Liquor/Moonshine' },
          { name: 'Flavoured', url: '/category/Liquor/Flavoured' },
          { name: 'Grain', url: '/category/Liquor/Grain' },
          { name: 'Liqueur', url: '/category/Liquor/Liqueur' },
          { name: 'Armagnac', url: '/category/Liquor/Armagnac' },
          { name: 'Cognac', url: '/category/Liquor/Cognac' },
          {
            name: 'Molecular Spirit',
            url: '/category/Liquor/Molecular Spirit',
          },
          { name: 'Raicilla', url: '/category/Liquor/Raicilla' },
          { name: 'Sotol', url: '/category/Liquor/Sotol' },
          {
            name: 'Sugar Cane Spirit',
            url: '/category/Liquor/Sugar Cane Spirit',
          },
          { name: 'Vermouth', url: '/category/Liquor/Vermouth' },
          { name: 'Gift Set', url: '/category/Liquor/Gift Set' },
          { name: 'Mixers', url: '/category/Liquor/Mixers' },
        ],
      },
    ],
  },
];

function TopNavigation({ deliveryToAddress, user, deliveryAddress, updateDeliveryAddress }) {
  const [display, changeDisplay] = useState('none');
  const [hoveredNav, setHoveredNav] = useState(null); 
  const deliveryAddressStr = deliveryAddress?.address
    ? `${deliveryAddress?.address.addrLine1} , ${deliveryAddress?.address.city} , ${deliveryAddress?.address.addrState} , ${deliveryAddress?.address.postCode}`
    : '';
  const { colorMode } = useColorMode();
  const location = useLocation();
  const { onDeliveryClose } = useDisclosure();
  const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
  const [isDeliveryAddressOpen, setIsDeliveryAddressOpen] = useState(false);
  const [isDeliveryAddressEdit, setIsDeliveryAddressEdit] = useState(false);
   const [geoAddress, setGeoAddress] = useState('')
   const [geoPostCode, setGeoPostCode] = useState('')
  const [IPcountry, setIPCountry] = useState('')
  const [IPlatitude, setIPLatitude] = useState('')
  const [IPlongitude, setIPLongitude] = useState('')
  const [client, setClient] = useState(null)
  useEffect(() => {
    const createClient = async () => {
      const credentials = await Auth.currentCredentials();
      const clientData = new Location({
        credentials,
        region: awsExports.aws_project_region,
      });

      setClient(clientData);
      console.log("clientData", clientData);
    };
    createClient();
  }, []);
  const fetchLocationBasedOnIP = async () => {
        try {
          const response = await fetch('https://geolocation-db.com/json/');
          const data = await response.json();
          setIPCountry(data.country_name);
          setIPLatitude(data.latitude);
          setIPLongitude(data.longitude);
          const searchResult = await searchAddress(data?.longitude, data?.latitude);
          setGeoAddress(searchResult.Results[0]?.Place?.Label.split(','));
          console.log('locationData', data);
        } catch (error) {
          console.log('error in Location', error);
        }
      };

  useEffect(() => {
    const getLocation = async () => {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        console.log("position", position);
        setIPLatitude(position?.coords?.latitude);
        setIPLongitude(position?.coords?.longitude);
        const searchResult = await searchAddress(position?.coords?.longitude, position?.coords?.latitude);
        setGeoAddress(searchResult.Results[0]?.Place?.Label.split(','));
        setGeoPostCode(searchResult.Results[0]?.Place?.PostalCode.slice(0, 5))
        // console.log("geoAdd", searchResult.Results[0]?.Place?.Label.split(','));
      } catch (error) {
        if (error.message.includes('User denied Geolocation')) {
          fetchLocationBasedOnIP()
        }
        else {
        console.error("Error Code = " + error.code + " - " + error.message);
        }
      }
    };
    if (client && !user && !deliveryAddress) {
      getLocation();
    }
  }, [client]);


  const searchAddress = async (lon, lat) => {
          const params = {
          Position: [lon, lat],
        IndexName: awsExports.aws_geo_mapIndexName,
        MaxResults: 1,
        Language: "de"
      };

    try {
      const addrData = await client.searchPlaceIndexForPosition(params).promise();
      console.log("addrData", addrData)
      return addrData
    } catch (err) {
      console.log("errin add", err);
      return false;
    }
  }
  
  if (deliveryToAddress && (deliveryToAddress?.latitude !== deliveryAddress?.address?.latitude || deliveryToAddress?.longitude !== deliveryAddress?.address?.longitude  ) ) {
    updateDeliveryAddress({
      address: {
        addrLine1: deliveryToAddress?.addrLine1,
        city: deliveryToAddress?.city,
        addrState: deliveryToAddress?.addrState,
        postCode: deliveryToAddress?.postCode,
        latitude: deliveryToAddress?.latitude,
        longitude:deliveryToAddress?.longitude,
      }, 
      lat: deliveryToAddress?.latitude,
      lon: deliveryToAddress?.longitude,
      deliveryBasedOnIP: false
    })
    console.log("deliveryAddresss", deliveryAddress, deliveryToAddress, geoAddress)
  }
  
  if (searchAddress && geoAddress.length > 0 && !deliveryAddress && !deliveryToAddress) {
    updateDeliveryAddress({
      address: {
        // addrLine1: geoAddress[0].trim(),
        // city: geoAddress[1].trim(),
        // addrState: geoAddress[2].trim().split(' ')[0].trim(),
        // postCode: geoAddress[2].trim().split(' ')[1].trim().slice(0, 5),
        latitude: IPlatitude,
        longitude:IPlongitude,
      }, 
      lat: IPlatitude,
      lon: IPlongitude,
      deliveryBasedOnIP: true
    })
    console.log("deliveryAddresss", deliveryAddress, deliveryToAddress, geoAddress)
  }
  const deliveryAddressModalClose = () => {
    if (location.pathname === '/storelocator' && !deliveryAddress) {
      navigate('/');
    }
    setIsDeliveryAddressOpen(false);
    setIsDeliveryAddressEdit(false);
  };


  return (
    <>
      <Box className="maimBg" borderColor='gray.400' borderWidth='0px 0px 1px 0px'>
        <Box width="100%">
          <Flex
            direction="row"
            justifyContent="flex-end"
            mh={14}
            alignItems={"flex-end"}
            bg={colorMode==='light'?'White':'black'}
            px={4}
           >
             <HStack fontWeight="bold" fontSize={{base:'12px' , md:"16px" , lg :"18px"}}>
              {user ? (
                <>
                  <Text textTransform="uppercase" color="brand.red">
                    Delivery to
                  </Text>
                  <Text> &gt; </Text>
                  <Text>
                    {deliveryToAddress?.firstName} -{' '}
                    {deliveryToAddress?.addressType}
                  </Text>
                  <IconButton
                    variant="outline"
                    color="brand.red"
                    border="none"
                    icon={<BsPencil fontSize="xl" />}
                    onClick={() => {
                      setIsDeliveryOpen(true);
                    }}
                  />
                </>
              ) : deliveryAddress?.address?.addrLine1 ? (
                <>
                  <Text textTransform="uppercase" color="brand.red">
                    Delivery to
                  </Text>
                  <Text> &gt; </Text>
                  <Tooltip hasArrow label={deliveryAddressStr}>
                    <Text cursor="default">{`${deliveryAddressStr?.slice(
                      0,
                      10,
                    )}...`}</Text>
                  </Tooltip>
                  <IconButton
                    variant="outline"
                    colorScheme="red"
                    border="none"
                    icon={<BsPencil fontSize="xl" />}
                    onClick={() => {
                      setIsDeliveryAddressEdit(true);
                    }}
                  />
                </>
              ) : location.pathname !== '/' ? (
                <>
                  {' '}
                  <Text textTransform="uppercase" color="brand.red">
                    Delivery to
                  </Text>
                  <Text> &gt; </Text>
                  <Button
                    ml="2"
                    onClick={() => {
                      setIsDeliveryAddressEdit(true);
                    }}
                    type="submit"
                    _hover={{ background: 'brand.red' }}
                    data-cy="addDeliveryAddressbtn"
                  >
                    {' '}
                    Add
                  </Button>
                </>
              ) : null}
              ;
            </HStack>
          </Flex>
        </Box>
      </Box>
      <DeliveryTo
        isOpen={isDeliveryOpen}
        setIsDeliveryOpen={setIsDeliveryOpen}
        onClose={onDeliveryClose}
      />
      {!user &&
      location.pathname !== '/signin/' &&
      location.pathname !== '/about-us' &&
      location.pathname !== '/careers' &&
      (location.pathname !== '/' || deliveryAddress) &&
      (!deliveryAddress || isDeliveryAddressEdit) ? (
        <DeliveryAddressSelectorModal
          isOpen={isDeliveryAddressEdit || isDeliveryAddressOpen}
          deliveryAddrModalClose={deliveryAddressModalClose}
        />
      ) : null}
    </>
  );
}

const stateMapper = (state) => ({
  user: state.auth?.user,
  deliveryAddress: state.deliveryAddress?.deliveryAddress,
});

const dispatchMapper = (dispatch) => ({
  updateDeliveryAddress: dispatch.deliveryAddress.updateDeliveryAddress,
});

export default connect(stateMapper, dispatchMapper)(memo(TopNavigation));

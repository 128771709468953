import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Text,
    Link,
  } from '@chakra-ui/react';
  import React from 'react';
  const UpdateDoorDashNotificationView = (props) => {
    const { updatedDoorDashOrderDetails, isUpdateDoorDashOrderOpen, onUpdateDoorDashOrderClose,setUpdateDoorDashOrderOpen } = props;
    console.log("updatedDoorDashOrderDetails", updatedDoorDashOrderDetails)
    const cancelRef = React.useRef();

    const timestamp = updatedDoorDashOrderDetails.dropoff_time_estimated;
const date = new Date(timestamp);

const options = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true 
};
const formattedDateTime = date.toLocaleString('en-US', options);
console.log("formattedDateTime", formattedDateTime);
  
    return (
      <AlertDialog
        isOpen={isUpdateDoorDashOrderOpen}
        leastDestructiveRef={cancelRef}
        onClose={onUpdateDoorDashOrderClose}
        width={"70%"}
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxW={"40%"} maxH={"50%"}>
            <AlertDialogHeader color="White" fontSize="lg" bg="brand.red">
              Order Updated
            </AlertDialogHeader>
            <AlertDialogCloseButton color="White" />
            <AlertDialogBody textAlign="center" overflowY="auto">
              { updatedDoorDashOrderDetails.event_name === "DELIVERY_CANCELLED" ? (
                <><Box  mt="3">
                <p> <b style={{color:'black',fontWeight:'bold'}}>{updatedDoorDashOrderDetails.event_name}</b> for your order <b style={{color:'black',fontWeight:'bold'}}>{updatedDoorDashOrderDetails.external_delivery_id?.split('-')[0]}</b>.</p>
              </Box></>
              ): (
                <><Box  mt="3">
                <p> <b style={{color:'black',fontWeight:'bold'}}>{updatedDoorDashOrderDetails.event_name}</b> for your order <b style={{color:'black',fontWeight:'bold'}}>{updatedDoorDashOrderDetails.external_delivery_id?.split('-')[0]}</b>! The order should arrive at<b style={{color:'black',fontWeight:'bold'}}> {formattedDateTime}</b>.</p>
              </Box>
              <Box  mt="3">
                We noticed that your address  may be difficult to find.Call or text <b style={{color:'black',fontWeight:'bold'}}> {updatedDoorDashOrderDetails.dasher_phone_number} </b> to coordinate a handoff with your Dasher 
              </Box></>
              )}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box mt="4">
              <span style={{ marginRight: '4px' }}>You can track the status </span>
        <a 
                style={{ color:'blue', textDecoration:'none' }} 
                  href={updatedDoorDashOrderDetails.tracking_url} 
                target="_blank"
                onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
                 onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}>
    
                    {updatedDoorDashOrderDetails.tracking_url}
    </a>
  </Box>
</div>


            </AlertDialogBody>
            <AlertDialogFooter sx={{ margin: 'auto' }}>
              <Button
                ref={cancelRef}
                onClick={()=>{
                  setUpdateDoorDashOrderOpen(false)
                }}
                variant="cancel-button"
              >
                Okay
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };
  export default UpdateDoorDashNotificationView;
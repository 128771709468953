import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Text,
    Textarea,
    ModalFooter,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    useDisclosure,
    Flex,
    ModalBody,
    ModalContent,
    ModalCloseButton,
  } from '@chakra-ui/react';
  import React, { useEffect, useRef, useState, useCallback } from 'react';
  import { graphql } from '../../../utils/api';
  import { API, graphqlOperation } from 'aws-amplify';
  import {
      updateConfirmSignup,
    updateOrderShipment,
  } from '../../../graphql/mutations';
  const ReplaceOrderShipmentNotificationView = (props) => {
    const { replaceOrderDetails,isreplaceOrderOpen,setReplaceModalOpen,setReplaceOrderDetails, onReplaceOrderclose,isOpen,replaceOrderOpen,replaceModalOpen } = props;
    const [orderShipmentDetails, setOrderShipmentDetails] = useState();
    const [showMessage,setShowMessage]=useState()
    const [message, setMessage] = useState('');
    const [userId,setUserId]=useState()
    const [assignedStoreName,setAssignedStoreName]=useState()
    const [id,setId]=useState()
    const [orderId,setOrderId]=useState()
   
  //   if(replaceOrderDetails){
  //   setUserId(replaceOrderDetails[0]?.userId)
  //  setAssignedStoreName(replaceOrderDetails[0]?.assignedStoreName)
  //  setId(replaceOrderDetails[0]?.id)
  //  setOrderId(replaceOrderDetails[0]?.orderId)
  //   }
   // console.log("UpdatedOrderDetails:",updatedOrderDetails);
   console.log('nandakumar',replaceOrderOpen,replaceOrderDetails)
  
   function convertAWSTimeToRegularTime(awsTime) {
    // Create a new Date object from the AWS time string
    const awsDate = new Date(awsTime);
  
    // Convert AWS time to local time
    const localDate = awsDate.toLocaleString();
  
    return localDate;
  }
  const handleSendMessage= async (orderId,shipmentId,userId,assignedStoreName) => {
    console.log('Sending message:', message);
    // Here you can add the code to actually send the message (e.g., API call)
    // After sending the message, you can clear the message and close the modal
    const currentTime=new Date()
    const UpdateOrderInput = {
      // id: messageId,
      // orderId:messageOrderId,
      assignedStoreId: replaceOrderDetails[0]?.assignedStoreId,
       userId:replaceOrderDetails[0]?.userId,
      messageStatus:(showMessage && showMessage.length>0)? [...showMessage,{statusTime:currentTime,messageStatus:message,userId:replaceOrderDetails[0]?.userId,updatedBy:"customer", assignedStoreId: replaceOrderDetails[0]?.assignedStoreId}]:[{statusTime:currentTime,messageStatus:message,userId:replaceOrderDetails[0]?.userId,updatedBy:"customer"}]
    };
    const graphqlQuery = await graphqlOperation(updateOrderShipment, {
      input: UpdateOrderInput,
    });
    await graphql(graphqlQuery);
   // getInProgressOrders();
    setMessage('')
    onMessageModalClose()
    // getOrdersList('Created')
};
const {
  isOpen: isMessageModalOpen,
  onClose: onMessageModalClose,
  onOpen: onMessageModalOpen,
} = useDisclosure();
    const cancelRef = React.useRef();
    const handleClick=async()=>{
        const currentTime=new Date()
        const UpdateOrderInput = { 
            id: replaceOrderDetails[replaceOrderDetails.length -1].id,
            orderId:replaceOrderDetails[replaceOrderDetails.length -1].orderId,
            merchantAccountId:replaceOrderDetails[0].merchantAccountId,
            assignedStoreId: replaceOrderDetails[replaceOrderDetails.length -1].assignedStoreId,
            messageStatus: JSON.stringify([...replaceOrderDetails,{statusTime:currentTime,messageStatus:'Yes',updatedBy:'customer',userId:replaceOrderDetails[0].userId, assignedStoreId:replaceOrderDetails[replaceOrderDetails.length -1].assignedStoreId }]),
            replaceOrder: true
          };
          const graphqlQuery = await graphqlOperation(updateOrderShipment, {
            input: UpdateOrderInput,
          });
          const res=await graphql(graphqlQuery);
  if(res){
    setReplaceOrderDetails(JSON.parse(res.data?.updateOrderShipment?.messageStatus))
  }

          await graphql(graphqlQuery);
          onReplaceOrderclose()
    }
  
    return (
      <>
      <Modal isOpen={replaceModalOpen} onClose={()=>setReplaceModalOpen(false)}>
<ModalOverlay style={{ backgroundColor: replaceOrderOpen ? 'transparent' : 'rgba(0, 0, 0, 0.5)' }} />
<ModalContent maxH="80vh">
  <ModalHeader>Send a Message</ModalHeader>
  <ModalCloseButton />
  <ModalBody>
      <div className="scrollable-content"
      style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {/* Loop through each message */}
          {replaceOrderDetails?.map((message, index) => (
              <Flex
                  key={index}
                   justify={message.updatedBy === 'customer' ? 'flex-end' : 'flex-start'}
                  mb={2}
              >
                  <Box
                      p={2}
                      borderRadius="20px" // Set a specific pixel value for borderRadius
                      border="1px solid"
                      //  backgroundColor={message.assignedStoreName === assignedStoreName ? 'yellow.200' : 'gray.100'}
                      // color={message.userId === assignedStoreName ? 'gray.600' : 'gray.700'}
                      maxWidth="75%"
                      // textAlign={message.userId === userId ? 'right' : 'left'}
                  >
                    
                      {/* Display userId and message status */}
                      {/* <Text>{${message.userId} - ${message.messageStatus}}</Text> */}

                      {/* {message.updatedBy==="customer"?
        <Text>{${message.userId} - ${message.messageStatus}}</Text> :

        <Text>{${message.assignedStoreName} - ${message.messageStatus}}</Text>} */}

<Text>
          {message.updatedBy === "customer" ? (
              <span style={{ fontWeight: 'bold' }}>{message.userId}</span>
          ) : (
              <span style={{ fontWeight: 'bold' }}>{message.assignedStoreName}</span>
          )}
          {' - '}
          {message.messageStatus}
      </Text>
                      
                      {/* Display message content */}
                      <Text>{message.content}</Text>
                      
                      {/* Display statusTime with smaller font size and lighter color */}
                      <Text fontSize="xs" color="gray.500" mt={1}>
                                      {convertAWSTimeToRegularTime(message.statusTime)}
                                  </Text>
                  </Box>
              </Flex>
          ))}
      </div>
      <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
          mt={1}
      />
  </ModalBody>
  <ModalFooter>
      {/* Close button */}
      <Button onClick={()=>setReplaceModalOpen(false)} mr={3}>
          Close
      </Button>
      {/* Send button */}
      <Button
          colorScheme="blue"
          onClick={() => handleSendMessage(orderId, id, userId,assignedStoreName)}
          mr={3}
      >
          Send
      </Button>
  </ModalFooter>
</ModalContent>
</Modal>
      <AlertDialog
        isOpen={replaceOrderOpen}
        //leastDestructiveRef={cancelRef}
        onClose={onReplaceOrderclose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader color="White" fontSize="lg" bg="brand.red">
              Message Updated
            </AlertDialogHeader>
            <AlertDialogCloseButton color="White" />
            <AlertDialogBody textAlign="center">
              <Box color="brand.red" mt="3">
                 {(replaceOrderDetails && replaceOrderDetails.length>0 ) ? (replaceOrderDetails[replaceOrderDetails.length-1]?.messageStatus) : (null)}


              </Box>
              
            </AlertDialogBody>
            <AlertDialogFooter sx={{ margin: 'auto' }}>
            
              <Button
                ref={cancelRef}
                onClick={handleClick}
                variant="cancel-button"
              >
                Yes
              </Button>

              <Button
                ref={cancelRef}
                onClick={onReplaceOrderclose}
                variant="cancel-button"
              >
                No
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>


</>   




    );
  };
  
  
  export default ReplaceOrderShipmentNotificationView;
  
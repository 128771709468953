export const accordionItemStyleProps = {
  bg: 'brand.lightpink',
  color: 'brand.red',
  borderBottomWidth: '3px',
  borderColor: 'brand.red',
};
// export const inputTextStyleProps = {
//   borderRadius: '4px',
// };
export const menuListStylesProps = {
  bg: 'brand.red',
  color: 'White',
  minW: '0',
  w: '100px',
};

import { Box, Divider, Flex, Link, Text, useColorMode } from '@chakra-ui/react';
import React from 'react';
import awsExports from '../../../aws-exports';

function QuickLinks() {
  const agentlink = awsExports.aws_redirect;
  const colorMode=useColorMode();
  const merchantlink = awsExports.aws_merchantredirect;
  const promotionallink = awsExports.aws_promtionalredirect;
  const handleBreadcrumbClick = () => {
    localStorage.removeItem('selectedStoreName');
    localStorage.removeItem('selectedStoreId');
  };
  return (
    <Box
      bg={colorMode.colorMode=== 'light' ? 'white':'black'}
      // color="black"
      px="5"
      display={{ base: 'block', xl: 'block' }}
      w='100%'
    >
      <Flex
        
        justifyContent="flex-end"
        direction={{ base: 'column', xl: 'row' }}
        fontFamily="Lexend"
        fontWeight="semibold" fontSize=""
      >
        <Flex direction="row" mb="3" mt="3"  h="20px" justifyContent="space-between" >
          {/* <Text as="p" size="sm" fontWeight="bold" pr="3">
            QUICK LINKS
          </Text> */}

          <Link href="/storelocator" _hover={{ color: "#B72618" }} pr="3" mr='3' onClick={handleBreadcrumbClick}>
            Stores Near me
          </Link>
          {/* <Divider orientation="vertical" pr="3" /> */}
          <Link href={merchantlink} target="_blank" pr="3" _hover={{ color: "#B72618" }} mr='3'>
            Merchant Portal
          </Link>
          {/* <Divider orientation="vertical" pr="3" /> */}
          <Link href={agentlink} target="_blank" pr="3" _hover={{ color: "#B72618" }} mr='3'>
            Agent Portal
          </Link>
          {/* <Divider orientation="vertical" pr="3" /> */}
          <Link
            href={promotionallink}
            target="_blank"
            pr="3"
            mr='3'
            _hover={{ color: "#B72618" }}
           
          >
            Partner with us{' '}
          </Link>
          {/* <Divider orientation="vertical" pr="3" /> */}
          <Link
            target="_blank"
            _hover={{ color: "#B72618" }}
            mr='3'
            href="/about-us"
            pr="3"
          >
            About Us{' '}
          </Link>
          {/* <Divider orientation="vertical" pr="3" /> */}
          <Link
            target="_blank"
            _hover={{ color: "#B72618" }}
            mr='3'
            href="/careers"
            pr="3"
          >
            Careers{' '}
          </Link>
        </Flex>

        {/* <Flex direction="row" justifyContent="top" mb="3" mt="3">
          <Text as="p" size="sm" fontWeight="bold" pr="3">
            REACH US
          </Text>
          <Text as="p">2162 Route 206, 2nd Floor, Montgomery NJ 0852, USA</Text>
        </Flex> */}
      </Flex>
    </Box>
  );
}
export default QuickLinks;

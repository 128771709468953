import { API, graphqlOperation } from 'aws-amplify';
import awsExports from '../aws-exports';
import {
  createCart as createCartQuery,
  deleteCart as deleteCartQuery,
  deleteCartShipment as deleteCartShipmentQuery,
  updateCart as updateCartQuery,
  updateCartShipment as updateCartShipmentQuery,
} from '../graphql/mutations';
import {
  getCart as getCartQuery,
  getStore as getStoreQuery,
} from '../graphql/queries';
import { number } from 'yup';
const defaultStatusHistory = {
  fromStatus: 'fromStatus',
  toStatus: 'toStatus',
  updatedBy: 'updatedBy',
};

function prepareLineItem(selectedStore, product,cart,searchKeyword) {

  console.log("SelectedStores23:::",selectedStore,product,searchKeyword,product.productName?.toLowerCase().includes(searchKeyword?.toLowerCase()));
  const newLineItem = {
    prodShortDesc: '',
    totalPrice: (selectedStore.availableQty<1) ? selectedStore.availableQty: parseFloat(selectedStore.price) * selectedStore.qtySelected,
    productId: selectedStore.newId,
    availableQty: selectedStore.availableQty,
    productName: (searchKeyword && searchKeyword !== "undefined") 
    ? searchKeyword
        .toLowerCase()
        .split(" ")
        .every((keyword) => product.productName?.toLowerCase().trim().includes(keyword))
      ? product.productName
      : selectedStore.storePosName
    : product.productName,
    prodCategory: product.prodCategory,
    imageSrc: product.imageSrc,
    upc: product?.id?.replace('UA_',''),
    storePosName: selectedStore.storePosName,
    storeSizeUom: selectedStore.storeSizeUom,
    storeDisplayQty: selectedStore.storeDisplayQty,
    size: product.size,
    uom: product.uom,
    // adding 0 qty to qtypurchased temporarily
    qtyPurchased:selectedStore.availableQty===0?selectedStore.availableQty: selectedStore.qtySelected,
    sortedData: JSON.stringify(selectedStore?.handleAutomaticDiscountConfig?.sortedData),
    packUnits:selectedStore.packUnits || product.packUnits,
    containerType:selectedStore.container || product.containerType || product.container,
    storeItemQty: selectedStore.storeItemQty || product.storeItemQty,
    // discountQtyPurchased: handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,cart?.cartShipment?.items?.find((item)=>item?.assignedStoreId==selectedStore?.storeId)?.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountQtyPurchased || selectedStore.qtySelected,
    // discountUnitPrice: handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,cart?.cartShipment?.items?.find((item)=>item?.assignedStoreId==selectedStore?.storeId)?.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountUnitPrice || selectedStore.price,
    unitPrice: parseFloat(selectedStore.price).toFixed(2) * 1,
    salePrice: parseFloat(selectedStore?.salePrice).toFixed(2) * 1,
    totalSalePrice: parseFloat(selectedStore.salePrice).toFixed(2) * selectedStore.qtySelected,
    // discountTotalPrice: handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,cart?.cartShipment?.items?.find((item)=>item?.assignedStoreId==selectedStore?.storeId)?.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountUnitPrice || selectedStore.price,
    //  discountTotalPrice: handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,cart?.cartShipment?.items?.find((item)=>item?.assignedStoreId==selectedStore?.storeId)?.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountTotalPrice || parseFloat(selectedStore.price).toFixed(2) * selectedStore.qtySelected,

    storeItemId: selectedStore.storeItemId,
    storeItemDesc: selectedStore.storeItemDesc,
    remainingAttributes: JSON.stringify(product.remainingAttributes),
    attributes: JSON.stringify(product.attributes),
    // upcId: selectedStore.upcId
  };
  if (product && product.itemInvalid) {
    newLineItem.itemInvalid = product.itemInvalid;
  }
  console.log("NewLineItem53::",newLineItem);
  return newLineItem;
}



// function handleDiscountPrice(discountData,lineItems,qty,unitPrice){
//   console.log('discountData,lineItems,qty,unitPrice',discountData,lineItems,qty,unitPrice)
// if(discountData){
//   const existsInCart = lineItems.filter((item)=> JSON.parse(item.attributes).size == discountData[0].size && item.prodCategory == discountData[0].category && (JSON.parse(item.attributes).uom).toLowerCase() === (discountData[0].uom).toLowerCase())
//   let count = 0;
//   if(false){
//       let discount ;
//       let message ;
//       for(let i in existsInCart)
//       {
      
//         count = count + existsInCart[i].qtyPurchased;
//       }
//     }
//     else{
//       count = qty;
//     }

//     console.log("existsInCart77::",existsInCart,count);

//     if(count<discountData[0].quantity)
//       {
//         console.log('inFirstIf')
//         return {
//           discountQtyPurchased:qty,
//           discountUnitPrice:unitPrice,
//           discountTotalPrice:parseFloat(unitPrice).toFixed(2) * qty
//         }
//       }
//       else if(count>discountData[discountData.length-1].quantity){
//         console.log('inSecondIf')
//         const discountObj = discountData[discountData.length-1] 
//         const discountUnitPrice = parseFloat(unitPrice - (unitPrice*discountObj.discount)/100).toFixed(2)
//         const discountTotalPrice = parseFloat(discountUnitPrice*discountObj.quantity + (count - discountObj.quantity)*unitPrice).toFixed(2)
//         return{
//         discountQtyPurchased:discountObj.quantity,
//         discountUnitPrice,
//         discountTotalPrice
//       }
//       }
//       else{
//         console.log('inThirdIf',count)
//           const itemsRange = findItemsInRange(discountData, count);
//           console.log('itemsInRange11',itemsRange)   
//           const discountObj = itemsRange[0]
//           const discountUnitPrice = parseFloat(unitPrice - (unitPrice*discountObj.discount)/100).toFixed(2)
//           const discountTotalPrice = parseFloat(discountUnitPrice*discountObj.quantity + (count - discountObj.quantity)*unitPrice).toFixed(2)
//           return{
//           discountQtyPurchased:discountObj.quantity,
//           discountUnitPrice,
//           discountTotalPrice   

//       }   

//       }
    
//       }

//   return {
//     discountQtyPurchased:qty,
//     discountUnitPrice:unitPrice,
//     discountTotalPrice:parseFloat(unitPrice).toFixed(2) * qty
//   }
// }

function findItemsInRange(sortedArray, count) {
  let left = 0;
  let right = sortedArray.length - 1;
  let startIndex = -1;
  let endIndex = -1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (sortedArray[mid].quantity <= count) {
      startIndex = mid;
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  if (startIndex === -1) {
    // No items in the given range
    if (count < sortedArray[0].quantity) {
      // Count is smaller than the min value of quantity
      return [sortedArray[0]];
    } else if (count >= sortedArray[sortedArray.length - 1].quantity) {
      // Count is larger than or equal to the max value of quantity
      const maxQuantityItem = sortedArray[sortedArray.length - 1];
      return [maxQuantityItem];
    }
  }

  // If count is exactly equal to a quantity, include the next element in the result
  if (sortedArray[startIndex].quantity === count) {
    const nextIndex = Math.min(startIndex + 1, sortedArray.length - 1);
    return [sortedArray[startIndex], sortedArray[nextIndex]];
  }

  if (startIndex === sortedArray.length - 1) {
    // If startIndex is at the end, return the last element
    return [sortedArray[startIndex]];
  }

  // Otherwise, return the elements within the range
  return sortedArray.slice(startIndex, startIndex + 2);
}



function calculateMultiples(count, sortedArray) {
  const sortedArrayDescending = [...sortedArray].sort((a, b) => b.quantity - a.quantity);

  let remainingCount = count;
  const result = [];
  let totalDiscountFlatAmount = 0;
  let totalQuantity = 0;
  if (remainingCount < sortedArrayDescending[sortedArrayDescending.length - 1]?.quantity) {
    // If count is smaller than the minQtyValue, return an empty array and totalDiscountFlatAmount
    return { result: result, totalDiscountFlatAmount: totalDiscountFlatAmount };
  }

  for (const item of sortedArrayDescending) {
    const multiplesUsed = Math.floor(remainingCount / item.quantity);
    console.log('totalDiscountitem',item,multiplesUsed)
    if (multiplesUsed > 0) {
      const discountFlatAmount = (item.discountFlatAmount || 0) * multiplesUsed;
      const pendingQty = item.quantity * multiplesUsed;
      totalDiscountFlatAmount += discountFlatAmount;
      console.log('totalDiscountitem191',result);
      result.push({
        multipleUsed: multiplesUsed,
        quantity: item.quantity,
        discountFlatAmount: discountFlatAmount,
        discountPercent:item.discount,
        pendingQty: pendingQty,
      });
      console.log('totalDiscountitem197',result);
      totalQuantity += multiplesUsed * item.quantity;
      remainingCount -= multiplesUsed * item.quantity;
    }
  }

  return { result: result, totalDiscountFlatAmount: totalDiscountFlatAmount,totalQuantity:totalQuantity };
}

function discountCart(createCartInput,eachStore,cartShipment,lineItemId,autoDiscountFlag){

  console.log("for partialCart:::",createCartInput,eachStore,cartShipment,lineItemId,autoDiscountFlag)
  if(createCartInput && eachStore)
  {
    console.log('createCartInput',createCartInput,eachStore)
  const discountData = eachStore?.handleAutomaticDiscountConfig?.sortedData[0];
  const discountDataArray = eachStore?.handleAutomaticDiscountConfig?.sortedData;
  const discountAmountFlag = !!(eachStore?.handleAutomaticDiscountConfig?.sortedData[0]?.discountFlatAmount); //logic to find if flat fee exists
  







  console.log('temp197',discountAmountFlag,discountData);





  if(discountAmountFlag){
  let cartShipments = createCartInput.cartShipment.items?createCartInput.cartShipment.items : createCartInput.cartShipment
  let tempLineItems = cartShipments.find((item)=>item.assignedStoreId == eachStore.storeId).lineItems
  console.log('SorttempLineItemsSort',tempLineItems)

  tempLineItems = [...tempLineItems].sort((a, b) => {
    return Number(b.unitPrice) - Number(a.unitPrice);
  });
  console.log('tempLineItemsSort',tempLineItems)
  let existsInCart,discountConfig;
  if(tempLineItems)
  tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null || isNaN(item?.salePrice) )
  if(discountData){
   existsInCart = tempLineItems.filter((li)=>(discountDataArray).some(item=>JSON.parse(li?.attributes)?.size == item?.size && li.prodCategory == item?.category && (JSON.parse(li?.attributes)?.uom).toLowerCase() === (item?.uom).toLowerCase()))
   discountConfig = discountDataArray.filter((item)=>(tempLineItems).some(li=>JSON.parse(li?.attributes)?.size == item?.size && li.prodCategory == item?.category && (JSON.parse(li?.attributes)?.uom).toLowerCase() === (item?.uom).toLowerCase()))
   
   console.log('temp196',existsInCart,discountConfig);
  }
  
  // let existsInCart1 = JSON.parse(JSON.stringify(existsInCart));

  // tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null || isNaN(item?.salePrice) )
  discountConfig = [...discountConfig].sort((a, b) => {
    return Number(a.quantity) - Number(b.quantity);
  });
  console.log('temp191',discountConfig);

  let count=0;
  if (existsInCart)
  {
  console.log('existsInCart123',existsInCart,discountData)

    for(let i in existsInCart)
      {
      
        count = count + existsInCart[i].qtyPurchased;
      }   
    
      console.log('countquotient3',count);
      // const countInRange =  findItemsInRange(discountConfig,count);
      const {result:qtyMultipliers,totalDiscountFlatAmount} =  calculateMultiples(count,discountConfig);
      console.log('totalDiscountFlatAmount',qtyMultipliers,totalDiscountFlatAmount);

      if(totalDiscountFlatAmount>0){
        // let remainingCount = count;
        let discountApplied =false;
        // const minValue = discountConfig[0].quantity;
        // while( remainingCount>=minValue){
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){



              item.discountQtyPurchased = 0
              item.discountUnitPrice =  0
              console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
              item.discountTotalPrice = 0
              if(!discountApplied){
              item.discount = totalDiscountFlatAmount ;
              discountApplied = true;
              }
              else{
              item.discount =  '0';
              }      
              // tempCount += item.discountQtyPurchased
            }
          })
        // }

      }

      // console.log('temp19',qtyMultipliers,discountConfig,count)

    // if(count>=countInRange[0]?.quantity){
    //   let tempCount = 0;
    //   // let quotient = Math.floor(count/countInRange[0].quantity)
    //   // console.log('countquotient2',count,quotient)
    //   tempLineItems.map((item,index)=>{
    //     if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
    //       item.discountQtyPurchased = 0
    //       item.discountUnitPrice =  0
    //       console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
    //       item.discountTotalPrice = 0
    //       if(index===0){
    //       item.discount =  (countInRange[0].discountFlatAmount).toFixed(2);
    //       }
    //       else{
    //       item.discount =  '0';
    //       }      
    //       tempCount += item.discountQtyPurchased
    //     }
    //   })
    // }

    // else if(countInRange.length===1 && count>countInRange[(countInRange.length-1)].quantity){
    //   if(){}    //handle multiples
    //   else{}
    // }

    else{
      tempLineItems.map((item)=>{
        if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
          item.discountQtyPurchased = 0 
          item.discountUnitPrice =  0
          item.discountTotalPrice = 0
          item.discount = '0'
        }
      })
    }
    }

    cartShipments.map((item)=>{
      if(item.assignedStoreId == eachStore.storeId){
        for (let i in item.lineItems){
          const liIndex = tempLineItems.findIndex((li)=>li.productId === item.lineItems[i].productId )
          if(liIndex>-1){
            item.lineItems[i]=tempLineItems[liIndex]
          }
          else if(!item.lineItems[i]?.discountQtyPurchased ){
            item.lineItems[i].discountQtyPurchased=0;
            item.lineItems[i].discountUnitPrice=0;
            item.lineItems[i].discountTotalPrice=0;
            item.lineItems[i].discount='0';
          }
        }
      }
    })

    console.log('tempLineItems',tempLineItems)
    console.log('createCartInput2',createCartInput)

    return createCartInput
  }
  else{
      let cartShipments = createCartInput.cartShipment.items?createCartInput.cartShipment.items : createCartInput.cartShipment
      let tempLineItems = cartShipments.find((item)=>item.assignedStoreId == eachStore.storeId).lineItems
      console.log('SorttempLineItemsSort',tempLineItems)
    
      tempLineItems = [...tempLineItems].sort((a, b) => {
        return Number(b.unitPrice) - Number(a.unitPrice);
      });
      console.log('tempLineItemsSort',tempLineItems)
      let existsInCart
      if(discountData){
       existsInCart = tempLineItems.filter((item)=> JSON.parse(item?.attributes)?.size == discountData?.size && item.prodCategory == discountData?.category && (JSON.parse(item?.attributes)?.uom).toLowerCase() === (discountData?.uom).toLowerCase())
      }
      
      // let existsInCart1 = JSON.parse(JSON.stringify(existsInCart));
    
      tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null || isNaN(item?.salePrice) )
      let count=0;
      if (existsInCart)
      {
      console.log('existsInCart123',existsInCart,discountData)
    
        for(let i in existsInCart)
          {
          
            count = count + existsInCart[i].qtyPurchased;
          }   
        
          console.log('countquotient3',count)
        
        if(count>=discountData.quantity){
          let tempCount = 0;
          let quotient = Math.floor(count/discountData.quantity)
          console.log('countquotient2',count,quotient)
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
              item.discountQtyPurchased = (quotient*discountData.quantity - tempCount >= item.qtyPurchased )? item.qtyPurchased : quotient*discountData.quantity - tempCount
              item.discountUnitPrice =  parseFloat(item.unitPrice - (item.unitPrice*discountData.discount)/100).toFixed(2)
              console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
              
              item.discountTotalPrice =  ((quotient*discountData.quantity - tempCount) >= item.qtyPurchased )? item.discountUnitPrice*item.discountQtyPurchased : item.discountUnitPrice*item.discountQtyPurchased + (item.qtyPurchased - item.discountQtyPurchased)*item.unitPrice
              
              tempCount += item.discountQtyPurchased
            }
          })
        }
    
    
        else{
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
              item.discountQtyPurchased = 0 
              item.discountUnitPrice =  0
              item.discountTotalPrice = 0
            }
          })
        }
        }
    
        cartShipments.map((item)=>{
          if(item.assignedStoreId == eachStore.storeId){
            for (let i in item.lineItems){
              const liIndex = tempLineItems.findIndex((li)=>li.productId === item.lineItems[i].productId )
              if(liIndex>-1){
                item.lineItems[i]=tempLineItems[liIndex]
              }
              else if(!item.lineItems[i]?.discountQtyPurchased ){
                item.lineItems[i].discountQtyPurchased=0;
                item.lineItems[i].discountUnitPrice=0;
                item.lineItems[i].discountTotalPrice=0;
              }
            }
          }
        })
    
        console.log('tempLineItems',tempLineItems)
        console.log('createCartInput2',createCartInput)
    
        return createCartInput
      
  }
  }

  else if(autoDiscountFlag){
    console.log('autoDiscountFlag',autoDiscountFlag)
      for (let i in cartShipment.lineItems){
        console.log('cartShipmentlineItems',cartShipment.lineItems)
        if(cartShipment.lineItems[i]?.sortedData){
          console.log('sortedData',cartShipment.lineItems[i]?.sortedData)
          return discountCart(null,null,cartShipment,cartShipment.lineItems[i]?.productId)
        }
       
      }
      return cartShipment;
  }

  else{
    const tempItem = cartShipment.lineItems.find((li)=>li.productId===lineItemId)
    cartShipment.discount = 0
    cartShipment.autoDiscount = 0
    cartShipment.saleDiscount = 0

    // for (let i in cartShipment.lineItems){
    //   cartShipment.lineItems[i].discountQtyPurchased = 0
    //   cartShipment.lineItems[i].discountUnitPrice = 0
    //   cartShipment.lineItems[i].discountTotalPrice = 0
    // }
    let discountData,discountDataArray,discountAmountFlag
    let tempLineItems = cartShipment.lineItems
    // let discountAmountFlag =true; //logic to find if flat fee exists

    if(tempItem?.sortedData && tempItem?.sortedData != 'null'){
      console.log("IntoElse481::",tempItem)
      discountData = (JSON.parse(tempItem?.sortedData))[0];
       discountDataArray = JSON.parse(tempItem?.sortedData);
       discountAmountFlag = !!((JSON.parse(tempItem?.sortedData))[0]?.discountFlatAmount); //logic to find if flat fee exists
    }
    console.log('discountDataArray408',discountDataArray,discountData,discountAmountFlag)


    if(discountAmountFlag){
    if(discountData){
      tempLineItems = [...tempLineItems].sort((a, b) => {
        return Number(b.unitPrice) - Number(a.unitPrice);
      });
    
      // const existsInCart = tempLineItems.filter((item)=> JSON.parse(item.attributes).size == discountData.size && item.prodCategory == discountData.category && (JSON.parse(item.attributes).uom).toLowerCase() === (discountData.uom).toLowerCase())
      if(tempLineItems)
      tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null ||isNaN(item?.salePrice) )
      
      const existsInCart = tempLineItems.filter((li)=>(discountDataArray).some(item=>JSON.parse(li?.attributes)?.size == item?.size && li.prodCategory == item?.category && (JSON.parse(li?.attributes)?.uom).toLowerCase() === (item?.uom).toLowerCase()))
      const discountConfig = discountDataArray.filter((item)=>(tempLineItems).some(li=>JSON.parse(li?.attributes)?.size == item?.size && li.prodCategory == item?.category && (JSON.parse(li?.attributes)?.uom).toLowerCase() === (item?.uom).toLowerCase()))

      // tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null ||isNaN(item?.salePrice) )
      let count=0;
      if (existsInCart)
      {
      console.log('existsInCart123',existsInCart,discountData)

        for(let i in existsInCart)
          {
          
            count = count + existsInCart[i].qtyPurchased;
          }   
        
          console.log('countquotient1',count)
          // const countInRange =  findItemsInRange(discountConfig,count);
          const {result:qtyMultipliers,totalDiscountFlatAmount} =  calculateMultiples(count,discountConfig);
          console.log('totalDiscountFlatAmount',qtyMultipliers,totalDiscountFlatAmount);
    
          if(totalDiscountFlatAmount>0){
            // let remainingCount = count;
            let discountApplied =false;
            // const minValue = discountConfig[0].quantity;
            // while( remainingCount>=minValue){
              tempLineItems.map((item)=>{
                if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
    
    
    
                  item.discountQtyPurchased = 0
                  item.discountUnitPrice =  0
                  console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
                  item.discountTotalPrice = 0
                  if(!discountApplied){
                  item.discount = totalDiscountFlatAmount ;
                  discountApplied = true;
                  }
                  else{
                  item.discount =  '0';
                  }      
                  // tempCount += item.discountQtyPurchased
                }
              })
            // }
    
          }
          // if(count>=countInRange[0]?.quantity){
          //   let tempCount = 0;
          //   // let quotient = Math.floor(count/countInRange[0].quantity)
          //   // console.log('countquotient2',count,quotient)
          //   tempLineItems.map((item,index)=>{
          //     if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
          //       item.discountQtyPurchased = 0
          //       item.discountUnitPrice =  0
          //       console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
          //       item.discountTotalPrice = 0
          //       if(index===0){
          //       item.discount =  (countInRange[0].discountFlatAmount).toFixed(2);
          //       }
          //       else{
          //       item.discount =  '0';
          //       }      
          //       tempCount += item.discountQtyPurchased
          //     }
          //   })
          // }
        else{
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
              item.discountQtyPurchased = 0 
              item.discountUnitPrice =  0
              item.discountTotalPrice = 0
              item.discount =  '0';

            }
          })
        }

        for (let i in cartShipment.lineItems){
          const liIndex = tempLineItems.findIndex((li)=>li.productId === cartShipment.lineItems[i].productId )
          if(liIndex>-1){
            cartShipment.lineItems[i]=tempLineItems[liIndex]
          }
          else if(!cartShipment.lineItems[i]?.discountQtyPurchased ){
            cartShipment.lineItems[i].discountQtyPurchased=0;
            cartShipment.lineItems[i].discountUnitPrice=0;
            cartShipment.lineItems[i].discountTotalPrice=0;
            cartShipment.lineItems[i].discount=0;

          }
        }
        console.log('discountCartShipment',cartShipment)
        return cartShipment
      }

    }
    return cartShipment
  }
  else{
    if(discountData){
      tempLineItems = [...tempLineItems].sort((a, b) => {
        return Number(b.unitPrice) - Number(a.unitPrice);
      });
    
      const existsInCart = tempLineItems.filter((item)=> JSON.parse(item.attributes).size == discountData.size && item.prodCategory == discountData.category && (JSON.parse(item.attributes).uom).toLowerCase() === (discountData.uom).toLowerCase())
      
      tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null ||isNaN(item?.salePrice) )
      let count=0;
      if (existsInCart)
      {
      console.log('existsInCart123',existsInCart,discountData)

        for(let i in existsInCart)
          {
          
            count = count + existsInCart[i].qtyPurchased;
          }   
        
          console.log('countquotient1',count)
        
        if(count>=discountData.quantity){ 
          let tempCount = 0;
          let quotient = Math.floor(count/discountData.quantity)
          console.log('countquotient',count,quotient)
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
              item.discountQtyPurchased = (quotient*discountData.quantity - tempCount >= item.qtyPurchased )? item.qtyPurchased : quotient*discountData.quantity - tempCount
              item.discountUnitPrice =  parseFloat(item.unitPrice - (item.unitPrice*discountData.discount)/100).toFixed(2)
              item.discountTotalPrice =  (quotient*discountData.quantity - tempCount >= item.qtyPurchased )? item.discountUnitPrice*item.discountQtyPurchased : item.discountUnitPrice*item.discountQtyPurchased + (item.qtyPurchased - item.discountQtyPurchased)*item.unitPrice
              tempCount += item.discountQtyPurchased
            }
          })
        }
        else{
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
              item.discountQtyPurchased = 0 
              item.discountUnitPrice =  0
              item.discountTotalPrice = 0
            }
          })
        }

        for (let i in cartShipment.lineItems){
          const liIndex = tempLineItems.findIndex((li)=>li.productId === cartShipment.lineItems[i].productId )
          if(liIndex>-1){
            cartShipment.lineItems[i]=tempLineItems[liIndex]
          }
          else if(!cartShipment.lineItems[i]?.discountQtyPurchased ){
            cartShipment.lineItems[i].discountQtyPurchased=0;
            cartShipment.lineItems[i].discountUnitPrice=0;
            cartShipment.lineItems[i].discountTotalPrice=0;
          }
        }
        console.log('discountCartShipment',cartShipment)
        return cartShipment
      }

    }
    return cartShipment
  }
  }
}

function discountRemoveCart(createCartInput,eachStore,cartShipment,lineItemId){
  console.log("cartShipment247:::",cartShipment);
  const tempItem = cartShipment.lineItems.find((li)=>li.productId===lineItemId)
  const tempItemIndex = cartShipment.lineItems.findIndex((li) => li.productId === lineItemId);

if (tempItemIndex !== -1) {
// Remove the item from the array using splice
cartShipment.lineItems.splice(tempItemIndex, 1);
}
  cartShipment.discount = 0
  cartShipment.autoDiscount = 0
  cartShipment.saleDiscount = 0

  
  // for (let i in cartShipment.lineItems){
  //   cartShipment.lineItems[i].discountQtyPurchased = 0
  //   cartShipment.lineItems[i].discountUnitPrice = 0
  //   cartShipment.lineItems[i].discountTotalPrice = 0
  // }
  let discountData,discountDataArray,discountAmountFlag
  // let discountAmountFlag =true; //logic to find if flat fee exists

  if(tempItem?.sortedData){
    discountData = (JSON.parse(tempItem?.sortedData))[0];
     discountDataArray = JSON.parse(tempItem?.sortedData);
     discountAmountFlag = !!((JSON.parse(tempItem?.sortedData))[0]?.discountFlatAmount); //logic to find if flat fee exists
  }
  console.log('discountDataArray408',discountDataArray)



  let tempLineItems = cartShipment.lineItems

  console.log('cartShipmentDiscount',cartShipment,lineItemId,discountData)

  if(discountAmountFlag){
    if(discountData){
      tempLineItems = [...tempLineItems].sort((a, b) => {
        return Number(b.unitPrice) - Number(a.unitPrice);
      });
    
      // const existsInCart = tempLineItems.filter((item)=> JSON.parse(item.attributes).size == discountData.size && item.prodCategory == discountData.category && (JSON.parse(item.attributes).uom).toLowerCase() === (discountData.uom).toLowerCase())
      
      const existsInCart = tempLineItems.filter((li)=>(discountDataArray).some(item=>JSON.parse(li?.attributes)?.size == item?.size && li.prodCategory == item?.category && (JSON.parse(li?.attributes)?.uom).toLowerCase() === (item?.uom).toLowerCase()))
      const discountConfig = discountDataArray.filter((item)=>(tempLineItems).some(li=>JSON.parse(li?.attributes)?.size == item?.size && li.prodCategory == item?.category && (JSON.parse(li?.attributes)?.uom).toLowerCase() === (item?.uom).toLowerCase()))

      tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null ||isNaN(item?.salePrice) )
      let count=0;
      if (existsInCart)
      {
      console.log('existsInCart123',existsInCart,discountData)

        for(let i in existsInCart)
          {
          
            count = count + existsInCart[i].qtyPurchased;
          }   
        
          console.log('countquotient1',count)
          // const countInRange =  findItemsInRange(discountConfig,count);
          const {result:qtyMultipliers,totalDiscountFlatAmount} =  calculateMultiples(count,discountConfig);
          console.log('totalDiscountFlatAmount',qtyMultipliers,totalDiscountFlatAmount);
    
          if(totalDiscountFlatAmount>0){
            // let remainingCount = count;
            let discountApplied =false;
            // const minValue = discountConfig[0].quantity;
            // while( remainingCount>=minValue){
              tempLineItems.map((item)=>{
                if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
    
    
    
                  item.discountQtyPurchased = 0
                  item.discountUnitPrice =  0
                  console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
                  item.discountTotalPrice = 0
                  if(!discountApplied){
                  item.discount = totalDiscountFlatAmount ;
                  discountApplied = true;
                  }
                  else{
                  item.discount =  '0';
                  }      
                  // tempCount += item.discountQtyPurchased
                }
              })
            // }
    
          }
          // if(count>=countInRange[0]?.quantity){
          //   let tempCount = 0;
          //   // let quotient = Math.floor(count/countInRange[0].quantity)
          //   // console.log('countquotient2',count,quotient)
          //   tempLineItems.map((item,index)=>{
          //     if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
          //       item.discountQtyPurchased = 0
          //       item.discountUnitPrice =  0
          //       console.log('discountTotalPricediscountTotalPrice',item.discountUnitPrice*item.discountQtyPurchased,)
          //       item.discountTotalPrice = 0
          //       if(index===0){
          //       item.discount =  (countInRange[0].discountFlatAmount).toFixed(2);
          //       }
          //       else{
          //       item.discount =  '0';
          //       }      
          //       tempCount += item.discountQtyPurchased
          //     }
          //   })
          // }
        else{
          tempLineItems.map((item)=>{
            if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
              item.discountQtyPurchased = 0 
              item.discountUnitPrice =  0
              item.discountTotalPrice = 0
              item.discount =  '0';

            }
          })
        }

        for (let i in cartShipment.lineItems){
          const liIndex = tempLineItems.findIndex((li)=>li.productId === cartShipment.lineItems[i].productId )
          if(liIndex>-1){
            cartShipment.lineItems[i]=tempLineItems[liIndex]
          }
          else if(!cartShipment.lineItems[i]?.discountQtyPurchased ){
            cartShipment.lineItems[i].discountQtyPurchased=0;
            cartShipment.lineItems[i].discountUnitPrice=0;
            cartShipment.lineItems[i].discountTotalPrice=0;
            cartShipment.lineItems[i].discount=0;

          }
        }
        console.log('discountCartShipment',cartShipment)
        return cartShipment
      }

    }
    return cartShipment
  }


else{
  if(discountData){
    tempLineItems = [...tempLineItems].sort((a, b) => {
      return Number(b.unitPrice) - Number(a.unitPrice);
    });
  
    const existsInCart = tempLineItems.filter((item)=> JSON.parse(item.attributes).size == discountData.size && item.prodCategory == discountData.category && (JSON.parse(item.attributes).uom).toLowerCase() === (discountData.uom).toLowerCase())
    
    tempLineItems = tempLineItems.filter((item)=>(item?.salePrice)=='0'||(item?.salePrice)==0||(item?.salePrice)==undefined||(item?.salePrice)==null ||isNaN(item?.salePrice) )
    let count=0;
    if (existsInCart)
    {
    console.log('existsInCart123',existsInCart,discountData)

      for(let i in existsInCart)
        {
        
          count = count + existsInCart[i].qtyPurchased;
          
        }   
      
        console.log('countquotient1',count)
      
      if(count>=discountData.quantity){
        let tempCount = 0;
        let quotient = Math.floor(count/discountData.quantity)
        console.log('countquotient',count,quotient)
        tempLineItems.map((item)=>{
          if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
            item.discountQtyPurchased = (quotient*discountData.quantity - tempCount >= item.qtyPurchased )? item.qtyPurchased : quotient*discountData.quantity - tempCount
            item.discountUnitPrice =  parseFloat(item.unitPrice - (item.unitPrice*discountData.discount)/100).toFixed(2)
            item.discountTotalPrice =  (quotient*discountData.quantity - tempCount >= item.qtyPurchased )? item.discountUnitPrice*item.discountQtyPurchased : item.discountUnitPrice*item.discountQtyPurchased + (item.qtyPurchased - item.discountQtyPurchased)*item.unitPrice
            tempCount += item.discountQtyPurchased
          }
        })
      }
      else{
        tempLineItems.map((item)=>{
          if( existsInCart.findIndex((product)=>product.productId === item.productId ) > -1 ){
            item.discountQtyPurchased = 0 
            item.discountUnitPrice =  0
            item.discountTotalPrice = 0
          }
        })
      }

      for (let i in cartShipment.lineItems){
        const liIndex = tempLineItems.findIndex((li)=>li.productId === cartShipment.lineItems[i].productId )
        if(liIndex>-1){
          cartShipment.lineItems[i]=tempLineItems[liIndex]
        }
        else if(!cartShipment.lineItems[i]?.discountQtyPurchased ){
          cartShipment.lineItems[i].discountQtyPurchased=0;
          cartShipment.lineItems[i].discountUnitPrice=0;
          cartShipment.lineItems[i].discountTotalPrice=0;
        }
      }
      console.log('discountCartShipment',cartShipment)
      return cartShipment
    }

  }
  return cartShipment
  }
}

function prepareCartShipment(selectedStore, product, cart, user, deliveryAddress,searchKeyword) {
  const tempLineItems = [prepareLineItem(selectedStore, product,cart,searchKeyword)];
  const subTotalProductAmount = tempLineItems
    .map((i) => i.totalPrice)
    .reduce((a, b) => a + b);

    const discountSubTotalProductAmount = tempLineItems
    .map((i) => i.discountTotalPrice)
    .reduce((a, b) => a + b);

    const subTotalSaleAmount = tempLineItems
    .map((i) => i.totalSalePrice)
    .reduce((a, b) => a + b);

    let discount = 0 ,autoDiscount = 0 ,saleDiscount = 0;

    for (let i in tempLineItems ){
      // if(tempLineItems[i].discountTotalPrice > 0 && tempLineItems[i].totalSalePrice ){
      //   discount+=()
      // }
      console.log('tempLineItems881)',tempLineItems[i],tempLineItems[i],tempLineItems[i]?.discount,Number(tempLineItems[i]?.discount))
      if (tempLineItems[i].discountTotalPrice>0 && parseFloat(tempLineItems[i]?.totalSalePrice)==0 || (tempLineItems[i]?.totalSalePrice)==null ||isNaN(tempLineItems[i]?.totalSalePrice)){
        console.log('isNaN(tempLineItems[i]?.totalSalePrice883)',tempLineItems[i].totalPrice, tempLineItems[i],tempLineItems[i].discountQtyPurchased)
        discount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.discountTotalPrice))).toFixed(2))
        autoDiscount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.discountTotalPrice))).toFixed(2))
      }
      else if ((tempLineItems[i]?.discount)>0 &&( parseFloat(tempLineItems[i]?.totalSalePrice)==0 || (tempLineItems[i]?.totalSalePrice)==null ||isNaN(tempLineItems[i]?.totalSalePrice))){
        console.log('discount888',tempLineItems[i].discount)
        discount +=Number(((parseFloat(tempLineItems[i]?.discount))).toFixed(2))
        autoDiscount +=Number(((parseFloat(tempLineItems[i]?.discount))).toFixed(2))
      }
      else if (parseFloat(tempLineItems[i]?.totalSalePrice)>0){
        discount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.totalSalePrice))).toFixed(2))
        saleDiscount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.totalSalePrice))).toFixed(2))

      }

    }

  console.log('cartObjCalculate1',saleDiscount,autoDiscount,discount)

  const newCartShipment = {
    assignedStoreId: selectedStore.storeId,
    deliveryType: selectedStore.deliveryType,
    assignedStoreName: selectedStore.storeName,
    isInServicableArea:selectedStore.isInServicableArea,
    deliveryAddress: {
      addrLine1: user ? user.deliveryToAddress.addrLine1 : deliveryAddress ? deliveryAddress.address.addrLine1 : 'New Market Rd E',
      addrLine2: user ? user.deliveryToAddress.addrLine2 :  deliveryAddress ? deliveryAddress.address.addrLine2 :'a',
      city: user ? user.deliveryToAddress.city : deliveryAddress ? deliveryAddress.address.city : 'Immokalee',
      state: user ? user.deliveryToAddress.addrState : deliveryAddress ? deliveryAddress.address.addrState : 'Florida',
      country: user ? user.deliveryToAddress.country : 'USA',
      postCode: user ? user.deliveryToAddress.postCode : deliveryAddress ? deliveryAddress.address.postCode : 34142,
      latitude: user ? user.deliveryToAddress.latitude : deliveryAddress ? deliveryAddress.address.latitude : 40,
      longitude: user ? user.deliveryToAddress.longitude : deliveryAddress ? deliveryAddress.address.longitude : -71,
    },
    lineItems: tempLineItems,
    statusHistory: defaultStatusHistory,
    updatedBy: user ? user.userId : 1111111111111111,
    subOrderAmount: 0,
    subTotalDeliveryCharges: 0,
    // subTotalMerchantCharges: 0,
    subTotal1800platformfee: 0,
    subTotalCardProcessingFee: 0,
    subTotalAmount: 0,
    subTotalDiscount: 0,
    subTotalProductAmount: parseFloat(subTotalProductAmount).toFixed(2) * 1,
    discountSubTotalProductAmount : parseFloat(discountSubTotalProductAmount).toFixed(2) * 1,
    discount : parseFloat(discount).toFixed(2) * 1,
    autoDiscount : parseFloat(autoDiscount).toFixed(2) * 1,
    saleDiscount : parseFloat(saleDiscount).toFixed(2) * 1,
    subTotalSaleAmount : parseFloat(subTotalSaleAmount).toFixed(2) * 1,
    subTotalTax: 0,
    subTotalTipAmount: 0,
    userId: user ? user.userId : 1111111111111111,
  };

  if (selectedStore && selectedStore.actionType) {
    newCartShipment.actionType = selectedStore.actionType;
    newCartShipment.cartId = selectedStore.cartId;
  }

  return newCartShipment;
}

function calculateTotals(cartObj, selectedStore, product) {

  // Update totalPrice, subTotalProductAmount, totalAmount & totalProductAmount
  const sameCartShipment = cartObj.cartShipment
          .find(
            (eachCartShipment) =>
              eachCartShipment.assignedStoreId === selectedStore.storeId 
            //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
          )
  let discount = 0 ,autoDiscount = 0 ,saleDiscount = 0;       
  for (let i in sameCartShipment.lineItems){
    console.log('sameCartShipment957',sameCartShipment.lineItems[i],sameCartShipment.lineItems[i]?.discount)
    if(sameCartShipment.lineItems[i].discountTotalPrice>0 &&(parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice)==0||!parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice))){

      discount +=Number((sameCartShipment.lineItems[i].totalPrice - (parseFloat(sameCartShipment.lineItems[i]?.discountTotalPrice))).toFixed(2))
      autoDiscount +=Number((sameCartShipment.lineItems[i].totalPrice - (parseFloat(sameCartShipment.lineItems[i]?.discountTotalPrice))).toFixed(2))
     
    }
    else if(Number(sameCartShipment.lineItems[i]?.discount)>0 && (parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice)==0||!parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice))){

      discount +=Number(((parseFloat(sameCartShipment.lineItems[i]?.discount))).toFixed(2))
      autoDiscount +=Number(((parseFloat(sameCartShipment.lineItems[i]?.discount))).toFixed(2))
     
    }
    else if(parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice)>0){
      discount +=Number((sameCartShipment.lineItems[i].totalPrice - (parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice))).toFixed(2))
      saleDiscount +=Number((sameCartShipment.lineItems[i].totalPrice - (parseFloat(sameCartShipment.lineItems[i]?.totalSalePrice))).toFixed(2))
    }
  }

  cartObj.cartShipment
    .find(
      (eachCartShipment) =>
        eachCartShipment.assignedStoreId === selectedStore.storeId 
        // && eachCartShipment.deliveryType === selectedStore.deliveryType,
    )
    .lineItems.find(
      (eachLineItem) => eachLineItem.productId === selectedStore.newId,
    ).totalPrice =selectedStore.availableQty===0?0:
      parseFloat(selectedStore.price).toFixed(2) * selectedStore.qtySelected;

      cartObj.cartShipment
      .find(
        (eachCartShipment) =>
          eachCartShipment.assignedStoreId === selectedStore.storeId 
          // && eachCartShipment.deliveryType === selectedStore.deliveryType,
      )
      .lineItems.find(
        (eachLineItem) => eachLineItem.productId === selectedStore.newId,
      ).totalSalePrice =selectedStore.availableQty===0?0:
        parseFloat(selectedStore?.salePrice).toFixed(2) * selectedStore.qtySelected;
  
          // cartObj.cartShipment
          // .find(
          //   (eachCartShipment) =>
          //     eachCartShipment.assignedStoreId === selectedStore.storeId 
          //   //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
          // )
          // .lineItems.find(
          //   (eachLineItem) => eachLineItem.productId === selectedStore.newId,
          // ).discountTotalPrice = parseFloat(handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,sameCartShipment.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountTotalPrice) ;


  const subTotalProductAmount = cartObj.cartShipment
    .find(
      (eachCartShipment) =>
        eachCartShipment.assignedStoreId === selectedStore.storeId 
        //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
    )
    .lineItems.map((i) => i.totalPrice)
    .reduce((a, b) => a + b);

    const subTotalSaleAmount = cartObj.cartShipment
    .find(
      (eachCartShipment) =>
        eachCartShipment.assignedStoreId === selectedStore.storeId 
        //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
    )
    .lineItems.map((i) => i?.totalSalePrice)
    .reduce((a, b) => a + b);


    const discountSubTotalProductAmount = cartObj.cartShipment
    .find(
      (eachCartShipment) =>
        eachCartShipment.assignedStoreId === selectedStore.storeId 
        //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
    )
    .lineItems.map((i) => i.discountTotalPrice)
    .reduce((a, b) => a + b);

  cartObj.cartShipment.find(
    (eachCartShipment) =>
      eachCartShipment.assignedStoreId === selectedStore.storeId 
      //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
  ).subTotalProductAmount = parseFloat(subTotalProductAmount).toFixed(2) * 1;
  console.log('discountAutoDIscount',discount,autoDiscount)
  cartObj.cartShipment.find(
    (eachCartShipment) =>
      eachCartShipment.assignedStoreId === selectedStore.storeId 
      //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
  ).discount = parseFloat(discount).toFixed(2) * 1;

  cartObj.cartShipment.find(
    (eachCartShipment) =>
      eachCartShipment.assignedStoreId === selectedStore.storeId 
      //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
  ).autoDiscount = parseFloat(autoDiscount).toFixed(2) * 1;

  cartObj.cartShipment.find(
    (eachCartShipment) =>
      eachCartShipment.assignedStoreId === selectedStore.storeId 
      //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
  ).saleDiscount = parseFloat(saleDiscount).toFixed(2) * 1;

  cartObj.cartShipment.find(
    (eachCartShipment) =>
      eachCartShipment.assignedStoreId === selectedStore.storeId 
      //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
  ).subTotalSaleAmount = parseFloat(subTotalSaleAmount).toFixed(2) * 1;

  cartObj.cartShipment.find(
    (eachCartShipment) =>
      eachCartShipment.assignedStoreId === selectedStore.storeId 
      //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
  ).discountSubTotalProductAmount = parseFloat(discountSubTotalProductAmount).toFixed(2) * 1;

  cartObj.totalAmount = 0;
  cartObj.totalProductAmount = 0;
  console.log('cartObjCalculate',cartObj,saleDiscount,autoDiscount,discount)
  return cartObj;
}

function prepareCart(selectedStore, product, user, currentCart, deleteFlag, deliveryAddress,searchKeyword) {
  let cartObj = {};
  deleteFlag = deleteFlag ? true : deleteFlag;
  console.log("currentCart:::",product,currentCart);

  if (currentCart) {
    cartObj = currentCart;
    cartObj.cartShipment = cartObj.cartShipment.items;

    // update cartShipment or lineItem
    const sameCartShipment = cartObj.cartShipment.find(
      (eachCartShipment) =>
        eachCartShipment.assignedStoreId === selectedStore.storeId 
        // && eachCartShipment.deliveryType === selectedStore.deliveryType,
    );

    if (sameCartShipment) {
      //console.log('Same store');
      const sameProduct = sameCartShipment.lineItems.find(
        (eachLineItem) => eachLineItem.productId === selectedStore.newId,
      );
      if (sameProduct) {
        // console.log('Same store and same product');
        // Update the quanity
        const selectedShipment = cartObj.cartShipment.find(
          (eachCartShipment) =>
            eachCartShipment.assignedStoreId === selectedStore.storeId &&
             eachCartShipment.deliveryType === selectedStore.deliveryType
        );
        console.log("selectedShipment1107::",selectedShipment)
        if (selectedShipment){
          selectedShipment.deliveryAddress.addrLine1 = user ? user.deliveryToAddress.addrLine1 : deliveryAddress?.address?.addrLine1;
          selectedShipment.deliveryAddress.addrLine2 = user ? user.deliveryToAddress.addrLine2: deliveryAddress?.address?.addrLine2;
          selectedShipment.deliveryAddress.state = user ? user.deliveryToAddress.addrState: deliveryAddress?.address?.addrState;
          selectedShipment.deliveryAddress.city = user ? user.deliveryToAddress.city: deliveryAddress?.address?.city;
          selectedShipment.deliveryAddress.latitude = user ? user.deliveryToAddress.latitude: deliveryAddress?.address?.latitude;
          selectedShipment.deliveryAddress.longitude = user ? user.deliveryToAddress.longitude: deliveryAddress?.address?.longitude;
          selectedShipment.deliveryAddress.postCode = user ? user.deliveryToAddress.postCode: deliveryAddress?.address?.postCode;
          selectedShipment.deliveryAddress.country = user ? user.deliveryToAddress.country: "USA";

        for(let i=0;i<cartObj.cartShipment.length;i++)
        {
          if(cartObj.cartShipment[i].assignedStoreId === selectedStore.storeId)
          cartObj.cartShipment[i].deliveryAddress = selectedShipment.deliveryAddress;
        }
        console.log("CartObjDeliveryAddress681::",selectedShipment,JSON.parse(JSON.stringify(cartObj)));
        }




        cartObj.cartShipment
        .find(
          (eachCartShipment) =>
            eachCartShipment.assignedStoreId === selectedStore.storeId 
          //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
        ).deliveryType = selectedStore.deliveryType;

        cartObj.cartShipment
          .find(
            (eachCartShipment) =>
              eachCartShipment.assignedStoreId === selectedStore.storeId 
            //  &&  eachCartShipment.deliveryType === selectedStore.deliveryType,
          )
          .lineItems.find(
            (eachLineItem) => eachLineItem.productId === selectedStore.newId,
          ).qtyPurchased = selectedStore.qtySelected;

        cartObj.cartShipment
          .find(
            (eachCartShipment) =>
              eachCartShipment.assignedStoreId === selectedStore.storeId 
            //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
          )
          .lineItems.find(
            (eachLineItem) => (eachLineItem.productId === selectedStore.newId),
          ).itemInvalid = false;
       
          // cartObj.cartShipment
          // .find(
          //   (eachCartShipment) =>
          //     eachCartShipment.assignedStoreId === selectedStore.storeId 
          //   //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
          // )
          // .lineItems.find(
          //   (eachLineItem) => eachLineItem.productId === selectedStore.newId,
          // ).discountQtyPurchased = handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,sameCartShipment.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountQtyPurchased || selectedStore.qtySelected;
                  
          // cartObj.cartShipment
          // .find(
          //   (eachCartShipment) =>
          //     eachCartShipment.assignedStoreId === selectedStore.storeId 
          //   //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
          // )
          // .lineItems.find(
          //   (eachLineItem) => eachLineItem.productId === selectedStore.newId,
          // ).discountUnitPrice = handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,sameCartShipment.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountUnitPrice || sameProduct.unitPrice;
          
          cartObj.cartShipment
          .find(
            (eachCartShipment) =>
              eachCartShipment.assignedStoreId === selectedStore.storeId 
            //  &&  eachCartShipment.deliveryType === selectedStore.deliveryType,
          )
          .lineItems.find(
            (eachLineItem) => eachLineItem.productId === selectedStore.newId,
          ).salePrice = selectedStore?.salePrice;
          // cartObj.cartShipment
          // .find(
          //   (eachCartShipment) =>
          //     eachCartShipment.assignedStoreId === selectedStore.storeId 
          //   //  && eachCartShipment.deliveryType === selectedStore.deliveryType,
          // )
          // .lineItems.find(
          //   (eachLineItem) => eachLineItem.productId === selectedStore.newId,
          // ).discountTotalPrice = handleDiscountPrice(selectedStore?.handleAutomaticDiscountConfig?.sortedData,sameCartShipment.lineItems || [],selectedStore.qtySelected,parseFloat(selectedStore.price).toFixed(2) * 1)?.discountTotalPrice || sameProduct.totalPrice;

          // console.log("DiscountItems:::",sameProduct,currentCart,selectedStore);

      } else {
        // console.log('Same store but different product');
        cartObj.cartShipment
          .find(
            (eachCartShipment) =>
              eachCartShipment.assignedStoreId === selectedStore.storeId 
            //  && eachCartShipment.deliveryType !== selectedStore.deliveryType,
          )
          .lineItems.push(prepareLineItem(selectedStore, product,currentCart,searchKeyword));

          cartObj.cartShipment
          .find(
            (eachCartShipment) =>
              eachCartShipment.assignedStoreId === selectedStore.storeId 
            //  && eachCartShipment.deliveryType !== selectedStore.deliveryType,
            ).deliveryType = selectedStore.deliveryType;

            const selectedShipment = cartObj.cartShipment.find(
              (eachCartShipment) =>
                eachCartShipment.assignedStoreId === selectedStore.storeId &&
                 eachCartShipment.deliveryType === selectedStore.deliveryType
            );

            if (selectedShipment){
              console.log("selectedAddress", selectedShipment, user)
              selectedShipment.deliveryAddress.addrLine1 = user ? user.deliveryToAddress.addrLine1 : deliveryAddress?.address?.addrLine1;
              selectedShipment.deliveryAddress.addrLine2 = user ? user.deliveryToAddress.addrLine2: deliveryAddress?.address?.addrLine2;
              selectedShipment.deliveryAddress.state = user ? user.deliveryToAddress.addrState: deliveryAddress?.address?.addrState;
              selectedShipment.deliveryAddress.city = user ? user.deliveryToAddress.city: deliveryAddress?.address?.city;
              selectedShipment.deliveryAddress.latitude = user ? user.deliveryToAddress.latitude: deliveryAddress?.address?.latitude;
              selectedShipment.deliveryAddress.longitude = user ? user.deliveryToAddress.longitude: deliveryAddress?.address?.longitude;
              selectedShipment.deliveryAddress.postCode = user ? user.deliveryToAddress.postCode: deliveryAddress?.address?.postCode;
              selectedShipment.deliveryAddress.country = user ? user.deliveryToAddress.country: "USA";
      
              for(let i=0;i<cartObj.cartShipment.length;i++)
              {
                if(cartObj.cartShipment[i].assignedStoreId === selectedStore.storeId)
                cartObj.cartShipment[i].deliveryAddress = selectedShipment.deliveryAddress;
              }
              console.log("CartObjDeliveryAddress681::",selectedShipment,JSON.parse(JSON.stringify(cartObj)));
              }

      }
    } else {
      // console.log('Different store');
      cartObj.cartShipment.push(
        prepareCartShipment(selectedStore, product,currentCart, user,deliveryAddress,searchKeyword),
      );
    }
    if(user!=null) 
    cartObj.userId = user.userId;
  } else {
    // create cart
    cartObj = {
      anonymousId: '',
      belongsTo: '',
      userId: user ? user.userId : 1111111111111111, // userProfileId - In Case of Anonymous
      totalAmount: 0,
      totalDeliveryCharges: 0,
      totalDiscount: 0,
      totalProductAmount: 0,
      totalTaxAmount: 0,
      totalTipAmount: 0,
      channel: 'direct',
      userAgent: navigator.userAgent,
      cartShipment: [prepareCartShipment(selectedStore, product,currentCart, user, deliveryAddress,searchKeyword)],
    };
  }
  console.log('cartObj',cartObj)
  cartObj = calculateTotals(cartObj, selectedStore, product);

  // Is this delete cartId necessary
  delete cartObj.cartId;

  cartObj.cartShipment.forEach((cs) => {
    if (!cs.actionType) {
      delete cs.cartId;
      if (deleteFlag) {
        delete cs.id;
      }
    }
    cs.lineItems.forEach((li) => {
      delete li.id;
      delete li.qtySelected;
      delete li.updateBtnDisabled;
    });
  });
  console.log('cartObj1',cartObj)

  return cartObj;
}

function prepareUpdatedCart(selectedStore, product, qty, user, currentCart,deliveryAddress,searchKeyword) {
  // Remove the product from the existing cart
  let cartShipments = {};
  if (currentCart) {
    console.log("currentCart244:::",selectedStore,currentCart);
    cartShipments = currentCart?.cartShipment?.items || currentCart?.cartShipment;
    console.log("cartShipments:::",cartShipments);
    // Find the existing product - Remove the cart shipment if empty lineitems exist
    const sameCartShipment = cartShipments.map((eachCartShipment) => {
      let deleteFlag = false;
      eachCartShipment.lineItems.forEach((li,lineIndex) => {
        console.log('matchedid@',li.storePosName === selectedStore.storePosName,li.storeSizeUom === selectedStore.storeSizeUom)
        if((li.storePosName === selectedStore.storePosName &&
          li.storeSizeUom === selectedStore.storeSizeUom && eachCartShipment.assignedStoreId===selectedStore.storeId) ){
        li.availableQty = selectedStore.availableQty;
          }
        
        console.log("EachCartshipmentAfterDelete1:::",selectedStore);
        console.log("EachproductAfterDelete:::",li);
        console.log("EachCartAfterDelete:::",eachCartShipment);
       
        if (
          li.productName === selectedStore.prodFullName &&
          li.attributes === JSON.stringify(selectedStore.attributes) &&
          selectedStore.storeId !== eachCartShipment.assignedStoreId 
          // && eachCartShipment.assignedStoreId === '111111'
        ) {
          deleteFlag = true;
          console.log("inCondition833:::",product);
          eachCartShipment.lineItems.splice(lineIndex,1);
          li.itemInvalid = false;
        }
        else if(
          li.productName === selectedStore.prodFullName &&
          
          li.storeSizeUom.replace(/\s+/g, '') === selectedStore.storeSizeUom.replace(/\s+/g, '') &&
          selectedStore.storeId !== eachCartShipment.assignedStoreId 
          // && eachCartShipment.assignedStoreId === '111111'
        ){
          deleteFlag = true;
          console.log("inCondition845:::",product);
          eachCartShipment.lineItems.splice(lineIndex,1);
          li.itemInvalid = false;
        }

        else if (
          li.productName === selectedStore.prodFullName &&
          li.attributes === JSON.stringify(selectedStore.attributes) &&
          selectedStore.storeId === eachCartShipment.assignedStoreId &&
          li.itemInvalid === true
        ) {
          // li.itemInvalid = false;
         // const itemIndex = eachCartShipment?.lineItems?.findIndex((item) =>item.productId == product.newId && item.itemInvalid === true)
          console.log("productSelected858::",li.itemInvalid,li.productId,product.productId);
         if(li.productId === product.productId && li.itemInvalid === true){
          console.log("LineItemIndex856:::",li,lineIndex);
          // if(itemIndex>-1)
          // {
            li.itemInvalid = false;
            eachCartShipment.lineItems.splice(lineIndex,1);
          // }
        }
        }
      
        // li.discountQtyPurchased= handleDiscountPrice(JSON.parse(li?.sortedData),eachCartShipment.lineItems,li.qtySelected,li.unitPrice)?.discountQtyPurchased || li.qtySelected;
        // li.discountUnitPrice= handleDiscountPrice(JSON.parse(li?.sortedData),eachCartShipment.lineItems,li.qtySelected,li.unitPrice)?.discountUnitPrice || li.unitPrice;
        // li.discountTotalPrice= handleDiscountPrice(JSON.parse(li?.sortedData),eachCartShipment.lineItems,li.qtySelected,li.unitPrice)?.discountTotalPrice || parseFloat(li.unitPrice).toFixed(2) * li.qtySelected;
       

      });


     

      console.log("EachCartshipmentAfterDelete:::",eachCartShipment);
      console.log("deleteFlag1:::",deleteFlag,eachCartShipment.lineItems);

      if (deleteFlag && eachCartShipment.lineItems.length <= 0) {
        console.log("deleteFlag:::",deleteFlag,eachCartShipment.lineItems);
        eachCartShipment = {
          ...eachCartShipment,
          actionType: 'delete',
          cartId: currentCart.id,
        };
      } else {
        console.log('actionTYPEUPDATE',eachCartShipment)
        eachCartShipment = {
          ...eachCartShipment,
          actionType: 'update',
          cartId: currentCart.id,
        };
      }
     
      return eachCartShipment;
    });

    console.log("sameCartShipment269:::",sameCartShipment);

    const newCartShipment = sameCartShipment?.find(
      (eachCartShipment) =>
        eachCartShipment.assignedStoreId !== selectedStore.storeId
    );

    console.log("NewCartShipment274::",newCartShipment);

    if (newCartShipment) {
      selectedStore = {
        ...selectedStore,
        actionType: 'create',
        cartId: currentCart.id,
      };
    }

    const tempCart = currentCart;
    tempCart.cartShipment.items = sameCartShipment;

    // Updating the product Qty
    selectedStore.qtySelected = qty;
    // Sending the delete flag to not removing the cartId and cartShipmentId
    const deleteFlag = false;
    // Update the cart with new details


    console.log('updateCartInput',tempCart)
    const updateCartInput = prepareCart(
      selectedStore,
      { ...product, itemInvalid: false },
      // product,
      user,
      tempCart,
      deleteFlag,
      deliveryAddress,
      searchKeyword
    );

    return updateCartInput;
  }
}

async function getCart(cartId) {
  const graphqlQuery = graphqlOperation(getCartQuery, {
    id: cartId,
  });
  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const cartData = res.data.getCart;

  console.log("CartData325:::",cartData);
  // unitPrice & totalPrice has to be float from the api response
  if (cartData !== null) {
    cartData.cartShipment.items.forEach((eachCartShipment) => {
      eachCartShipment.lineItems.forEach((li) => {
        li.unitPrice = parseFloat(li.unitPrice).toFixed(2) * 1;
        // li.totalPrice = parseFloat(li.totalPrice).toFixed(2) * 1;
      });
    });
  }

  return cartData;
}

async function addToCart(selectedStore, product, user, deliveryAddress, currentCart,searchKeyword) {
  console.log("searchKeyword",selectedStore)
  const createCartInput1 = prepareCart(
    selectedStore,
    product,
    user,
    currentCart,
    null,
    deliveryAddress,
    searchKeyword
  );

  const createCartInput = discountCart(createCartInput1,selectedStore)

  const tempLineItems = createCartInput.cartShipment.find((cs)=>cs.assignedStoreId === selectedStore.storeId).lineItems
  console.log('tempLineItems858',tempLineItems)

  let discount = 0 ,autoDiscount = 0 ,saleDiscount = 0;
  for (let i in tempLineItems){
  console.log('tempLineItems8582',tempLineItems[i].discountTotalPrice>0, tempLineItems[i].discount>0 ,(parseFloat(tempLineItems[i]?.totalSalePrice)==0 || (tempLineItems[i]?.totalSalePrice) == null ||isNaN(tempLineItems[i]?.totalSalePrice) ))

    if(tempLineItems[i].discountTotalPrice>0 && (parseFloat(tempLineItems[i]?.totalSalePrice)==0 || (tempLineItems[i]?.totalSalePrice) == null ||isNaN(tempLineItems[i]?.totalSalePrice) )){
      console.log('discountautoDiscount1',tempLineItems[i].totalPrice,tempLineItems[i].discountTotalPrice,Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.discountTotalPrice))).toFixed(2)))
      discount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.discountTotalPrice))).toFixed(2))
      autoDiscount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.discountTotalPrice))).toFixed(2))
    }
    else if(Number(tempLineItems[i]?.discount)>0 && (parseFloat(tempLineItems[i]?.totalSalePrice)==0 || (tempLineItems[i]?.totalSalePrice) == null ||isNaN(tempLineItems[i]?.totalSalePrice)) ){
      console.log('discountautoDiscount1',tempLineItems[i].totalPrice,tempLineItems[i].discountTotalPrice,Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.discountTotalPrice))).toFixed(2)))
      discount +=Number((parseFloat(tempLineItems[i]?.discount)).toFixed(2))
      autoDiscount +=Number((parseFloat(tempLineItems[i]?.discount)).toFixed(2))
    }
    else if(parseFloat(tempLineItems[i]?.totalSalePrice)>0){
      discount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.totalSalePrice))).toFixed(2))
      saleDiscount +=Number((tempLineItems[i].totalPrice - (parseFloat(tempLineItems[i]?.totalSalePrice))).toFixed(2))
    }
  }
  createCartInput.cartShipment.find((cs)=>cs.assignedStoreId === selectedStore.storeId).discount = discount
  createCartInput.cartShipment.find((cs)=>cs.assignedStoreId === selectedStore.storeId).autoDiscount = autoDiscount
  createCartInput.cartShipment.find((cs)=>cs.assignedStoreId === selectedStore.storeId).saleDiscount = saleDiscount
  

  console.log("CreateCartInput3487::",discount,autoDiscount);
  console.log("CreateCartInput348::",createCartInput);

  try {
    const graphqlQueryProduct = graphqlOperation(createCartQuery, {
      input: createCartInput,
    });
    const res = await API.graphql({
      ...graphqlQueryProduct,
      authMode: 'API_KEY',
      authToken: awsExports.aws_appsync_apiKey,
    });

    const cartData = res.data.createCart;

    console.log("CreateCartData362::",cartData);

    // unitPrice & totalPrice has to be float from the api response
    if (cartData !== null) {
      cartData.cartShipment.items.forEach((eachCartShipment) => {
        eachCartShipment.lineItems.forEach((li) => {
          li.unitPrice = parseFloat(li.unitPrice).toFixed(2) * 1;
          li.totalPrice = parseFloat(li.totalPrice).toFixed(2) * 1;
        });
      });
    }

    return {
      success: true,
      cart: cartData,
    };
  } catch (e) {
    return { success: false, error: e };
  }
}

async function deleteCart(cartId) {
  try {
    const graphqlQuery = graphqlOperation(deleteCartQuery, {
      input: {
        id: cartId,
      },
    });
    await API.graphql({
      ...graphqlQuery,
      authMode: 'API_KEY',
      authToken: awsExports.aws_appsync_apiKey,
    });

    // deleteCart res need to be handle it properly
    // console.log('deleteCart res', res);
    return { success: true, cart: null };
  } catch (e) {
    return { success: false, error: e };
  }
}

async function updateCart(selectedStore, product, qty, user, currentCart,deliveryAddress,searchKeyword) {
  console.log('updatedCart@@13',currentCart,product)
  const updateCartInput1 = prepareUpdatedCart(
    selectedStore,
    product,
    qty,
    user,
    currentCart,
    deliveryAddress,
    searchKeyword
  );

  const updateCartInput = discountCart(updateCartInput1,selectedStore)
  console.log("updateCartInput478::",updateCartInput,searchKeyword);

  updateCartInput.cartShipment.forEach(item => {
    let discount = 0 ,autoDiscount = 0 ,saleDiscount = 0;
    for (let i in item.lineItems){
      if(item.lineItems[i].discountTotalPrice>0 && (parseFloat(item.lineItems[i]?.totalSalePrice)==0 || (item.lineItems[i]?.totalSalePrice) == null ||isNaN(item.lineItems[i]?.totalSalePrice)) ){
        
        discount +=Number((item.lineItems[i].totalPrice - (parseFloat(item.lineItems[i]?.discountTotalPrice))).toFixed(2))
        autoDiscount +=Number((item.lineItems[i].totalPrice - (parseFloat(item.lineItems[i]?.discountTotalPrice))).toFixed(2))
       
      }
      else if(Number(item.lineItems[i]?.discount)>0 && (parseFloat(item.lineItems[i]?.totalSalePrice)==0 || (item.lineItems[i]?.totalSalePrice) == null ||isNaN(item.lineItems[i]?.totalSalePrice)) ){
        
        discount +=Number((parseFloat(item.lineItems[i]?.discount)).toFixed(2))
        autoDiscount +=Number((parseFloat(item.lineItems[i]?.discount)).toFixed(2))
       
      }
      else if(parseFloat(item.lineItems[i]?.totalSalePrice)>0){
        discount +=Number((item.lineItems[i].totalPrice - (parseFloat(item.lineItems[i]?.totalSalePrice))).toFixed(2))
        saleDiscount +=Number((item.lineItems[i].totalPrice - (parseFloat(item.lineItems[i]?.totalSalePrice))).toFixed(2))
       
      }
    }
    // Calculate the sum of totalPrice values for lineItems within the current item
    const subTotalProductAmount = item.lineItems.reduce((total, lineItem) => {
      return total + parseFloat(lineItem.totalPrice); // Convert string to number
    }, 0);
      console.log("CartSubTotalProductAmount202::",subTotalProductAmount);

      const discountSubTotalProductAmount = item.lineItems.reduce((total, lineItem) => {
        return total + parseFloat(lineItem?.discountTotalPrice); // Convert string to number
      }, 0);
        console.log("CartSubTotalProductAmount607::",discountSubTotalProductAmount);
        
      const subTotalSaleAmount = item.lineItems.reduce((total, lineItem) => {
          return total + (parseFloat(lineItem?.totalSalePrice) ||0 ); // Convert string to number
        }, 0);

      
        console.log("CartSubTotalProductAmount689::",subTotalSaleAmount);

    // Update the subTotalProductAmount field for the current item
    item.subTotalProductAmount = subTotalProductAmount;
    item.discountSubTotalProductAmount = discountSubTotalProductAmount;
    item.subTotalSaleAmount = subTotalSaleAmount;
    item.discount = discount;
    item.autoDiscount = autoDiscount;
    item.saleDiscount = saleDiscount;
    console.log('cartObjCalculate2',saleDiscount,autoDiscount,discount)


  });

  try {
    console.log('updateCartInput@@@!!@##',updateCartInput)
    const graphqlQueryProduct = graphqlOperation(updateCartQuery, {
      input: updateCartInput,
    });
    const res = await API.graphql({
      ...graphqlQueryProduct,
      authMode: 'API_KEY',
      authToken: awsExports.aws_appsync_apiKey,
    });

    const cartData = res.data.updateCart;

    // unitPrice & totalPrice has to be float from the api response
    if (cartData !== null) {
      cartData.cartShipment.items.forEach((eachCartShipment) => {
        eachCartShipment.lineItems.forEach((li) => {
          li.unitPrice = parseFloat(li.unitPrice).toFixed(2) * 1;
          li.totalPrice = parseFloat(li.totalPrice).toFixed(2) * 1;
          li.totalSalePrice=parseFloat(li.totalSalePrice).toFixed(2)*1
        });
      });
    }

    console.log("LocalCartData:::",cartData);
    
    return {
      success: true,
      cart: cartData,
    };
  } catch (e) {
    return { success: false, error: e };
  }
}




async function deleteCartShipment(cartShipmentId) {
  try {
    const graphqlQuery = graphqlOperation(deleteCartShipmentQuery, {
      input: {
        id: cartShipmentId,
      },
    });
    await API.graphql({
      ...graphqlQuery,
      authMode: 'API_KEY',
      authToken: awsExports.aws_appsync_apiKey,
    });

    // deleteCartShipment res need to be handle it properly
    // console.log('deleteCartShipment res', res);
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
}

async function updateCartShipment(updatedCartShipmentObj,lineItemId) {
  try {
    console.log('updatedCartShipmentObj1',updatedCartShipmentObj)
    let subTotalProductAmount = 0;
    let discountSubTotalProductAmount = 0;
    let subTotalSaleAmount = 0;
    updatedCartShipmentObj.lineItems.forEach((li) => {
      if (li.qtySelected !=null){
      li.totalPrice = parseFloat(li.unitPrice).toFixed(2) * li.qtySelected;
      li.totalSalePrice = parseFloat(li.salePrice).toFixed(2) * li.qtySelected;
      // li.discountQtyPurchased= li?.sortedData? (handleDiscountPrice(JSON.parse(li?.sortedData),updatedCartShipmentObj.lineItems,li.qtySelected,li.unitPrice)?.discountQtyPurchased || li.qtySelected) :li.qtySelected ;
      // li.discountUnitPrice= li?.sortedData?(handleDiscountPrice(JSON.parse(li?.sortedData),updatedCartShipmentObj.lineItems,li.qtySelected,li.unitPrice)?.discountUnitPrice || li.unitPrice) :li.unitPrice ;
      // li.discountTotalPrice= li?.sortedData?(handleDiscountPrice(JSON.parse(li?.sortedData),updatedCartShipmentObj.lineItems,li.qtySelected,li.unitPrice)?.discountTotalPrice || parseFloat(li.unitPrice).toFixed(2) * li.qtySelected) :parseFloat(li.unitPrice).toFixed(2) * li.qtySelected ;
      subTotalProductAmount += li.totalPrice;
      discountSubTotalProductAmount += parseFloat(parseFloat(li.discountTotalPrice).toFixed(2));
      
      subTotalSaleAmount+= parseFloat(parseFloat(li?.totalSalePrice).toFixed(2));
      li.qtyPurchased = li.qtySelected;
      delete li.id;
      delete li.qtySelected;
      delete li.updateBtnDisabled;
      }
    });
    let updatedCartShipmentObj1= updatedCartShipmentObj
    if(lineItemId){
      updatedCartShipmentObj1 = discountCart(null,null,updatedCartShipmentObj,lineItemId)
      console.log('updatedCartShipmentObj1',updatedCartShipmentObj1)
    }
    let discount = 0 ,autoDiscount = 0 ,saleDiscount = 0;
    updatedCartShipmentObj1.lineItems.forEach((li) => {
      console.log('lidiscountTotalPrice1689',li,li?.discount,Number(li?.totalSalePrice))

      if (li.discountTotalPrice>0 && (parseFloat(li?.totalSalePrice)==0||!parseFloat(li?.totalSalePrice))){
        discount +=Number((li.totalPrice - (parseFloat(li?.discountTotalPrice))).toFixed(2))
        autoDiscount +=Number((li.totalPrice - (parseFloat(li?.discountTotalPrice))).toFixed(2))
      }
      else if (Number(li?.discount)>0 && (parseFloat(li?.totalSalePrice)==0||!parseFloat(li?.totalSalePrice))){
        discount +=Number((parseFloat(li?.discount)).toFixed(2))
        autoDiscount +=Number((parseFloat(li?.discount)).toFixed(2))
      }
      else if (parseFloat(li?.totalSalePrice)>0){
        discount +=Number((li.totalPrice - (parseFloat(li?.totalSalePrice))).toFixed(2))
        saleDiscount +=Number((li.totalPrice - (parseFloat(li?.totalSalePrice))).toFixed(2))
      }
    })
      updatedCartShipmentObj1.subTotalProductAmount = subTotalProductAmount || updatedCartShipmentObj.subTotalProductAmount ;
      // updatedCartShipmentObj1.discountSubTotalProductAmount = parseFloat(discountSubTotalProductAmount).toFixed(2) || updatedCartShipmentObj.discountSubTotalProductAmount ;
      updatedCartShipmentObj1.discount = discount ;
      updatedCartShipmentObj1.autoDiscount = autoDiscount ;
      updatedCartShipmentObj1.saleDiscount = saleDiscount ;

      updatedCartShipmentObj1.subTotalSaleAmount = subTotalSaleAmount ;

    console.log('updatedCartShipmentObj2',updatedCartShipmentObj)
  console.log('cartObjCalculate3',saleDiscount,autoDiscount,discount)

    delete updatedCartShipmentObj1.pickupOptions
    const graphqlQuery = graphqlOperation(updateCartShipmentQuery, {
      input: updatedCartShipmentObj,
    });
    await API.graphql({
      ...graphqlQuery,
      authMode: 'API_KEY',
      authToken: awsExports.aws_appsync_apiKey,
    });

    // updateCartShipment res need to be handle it properly
    // console.log('updateCartShipment res', res);
    return { success: true };
  } catch (e) {
    console.log('errorWhile',e)
    return { success: false, error: e };
  }
}



 function removeCartShipmentLineItem(cart, cartShipmentId, lineItemId) {
  if (cart.cartShipment.items.length === 1) {
    // console.log('Only 1 cart shipment so delete complete cart');
    if (cart.cartShipment.items[0].lineItems.length === 1)
      return deleteCart(cart.id);
  }

  const filteredCartShipment = cart.cartShipment.items.find(
    (eachCartShipment) => eachCartShipment.id === cartShipmentId,
  );
  if (filteredCartShipment.lineItems.length === 1) {
    // console.log(
    //   'Only 1 line items in cart shipment, so delete only cart shipment',
    // );
    return deleteCartShipment(filteredCartShipment.id);
  }
  // console.log('Delete the line item and update only the cartShipment');
  const lineItemIndex = filteredCartShipment.lineItems.findIndex(
    (eachLineItem) => eachLineItem.productId === lineItemId,
  );
  delete filteredCartShipment.isPickupOutOfStateFlag
  const orignalShipment = JSON.parse(JSON.stringify(filteredCartShipment));
  const tempCart = discountRemoveCart(null,null,orignalShipment,lineItemId);
 
    if(tempCart){
    filteredCartShipment.autoDiscount = tempCart?.autoDiscount
  filteredCartShipment.lineItems.splice(lineItemIndex, 1);
    }
  console.log('cartObjCalculate4',filteredCartShipment)
  return updateCartShipment(tempCart,lineItemId);
}

 function getInvalidCartFlag(cart) {
  let tempcartInvalidFlag = false;
  cart.cartShipment.items.forEach((eachCartShipment) => {
    eachCartShipment.lineItems.forEach((product) => {
      if (product?.itemInvalid) {
        tempcartInvalidFlag = true;
      }
    });
  });
  return tempcartInvalidFlag;
}

async function setInvalidCartFlag(cart) {
  // Update the cart by adding itemInvalid true
  const updatedCart = JSON.parse(JSON.stringify(cart));
  // updatedCart.cartShipment.items.forEach(async (eachCartShipment) => {
  //   eachCartShipment.lineItems.forEach((product) => {
  //     product.itemInvalid = true;
  //   });
  //   await(updateCartShipment(eachCartShipment));
  //   // const graphqlQuery = graphqlOperation(updateCartShipmentQuery, {
  //   //   input: eachCartShipment,
  //   // });
  //   // await API.graphql({
  //   //   ...graphqlQuery,
  //   //   authMode: 'API_KEY',
  //   //   authToken: awsExports.aws_appsync_apiKey,
  //   // });

  // });

  for(let i in updatedCart?.cartShipment?.items ){
    for(let j in updatedCart?.cartShipment?.items[i]?.lineItems)
    {
      updatedCart.cartShipment.items[i].lineItems[j].itemInvalid = true;
    }
    await(updateCartShipment(updatedCart?.cartShipment?.items[i]));
  }

  console.log('updatedCart',updatedCart)

  const cartFlag = await getInvalidCartFlag(updatedCart);
  return cartFlag;
}

async function getStore(storeId) {
  const graphqlQuery = graphqlOperation(getStoreQuery, {
    id: storeId,
  });
  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const storeData = res.data.getStore;
  return storeData;
}

export {
  getCart,
  addToCart,
  updateCart,
  removeCartShipmentLineItem,
  updateCartShipment,
  deleteCart,
  getInvalidCartFlag,
  setInvalidCartFlag,
  prepareCart,
  getStore,
  discountCart,
};

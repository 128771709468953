import { Box, Divider, Flex, Link, Text, Button, Grid } from '@chakra-ui/react';
import React from 'react';
import { HStack,Image ,useColorModeValue,Icon} from  '@chakra-ui/react';
import awsExports from '../../../aws-exports';
import { useColorMode } from "@chakra-ui/react"; 
import { FiExternalLink } from 'react-icons/fi';
import { FaApple,FaGooglePlay,FaFacebook, FaInstagram } from "react-icons/fa";


export default function Footer() {
  const { colorMode } = useColorMode();

  const agentlink = awsExports.aws_redirect;
  const merchantlink = awsExports.aws_merchantredirect;
  const promotionallink = awsExports.aws_promtionalredirect;
  const openPlayStore = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const deepLink = "spiritscustomerapp://127.0.0.1:19000";
    const playStoreURL = "https://play.google.com/store/apps/details?id=com.spirits.customer&pcampaignid=web_share";
    
    if (isAndroid) {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = deepLink;
      document.body.appendChild(iframe);
      setTimeout(() => {
        document.body.removeChild(iframe);
        window.open(playStoreURL, "_blank");
      }, 1500);
    } else {
      window.open(playStoreURL, "_blank");
    }
  };
  
  const openAppStore = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const appScheme = "spiritscustomerapp://127.0.0.1:19000";
    const appStoreURL = "https://apps.apple.com/us/app/1800spirits-alcohol-ordering/id6463494662";
  
    if (isIOS) {
      // Create an iframe to attempt to open the app
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = appScheme;
      document.body.appendChild(iframe);
        setTimeout(() => {
        document.body.removeChild(iframe);
        window.open(appStoreURL, "_blank"); 
      }, 1500);
    } else {
      window.open(appStoreURL, "_blank"); 
    }
  };
  
  
  
  const currentYear = new Date().getFullYear();
  const handleBreadcrumbClick = () => {
    localStorage.removeItem('selectedStoreName');
    localStorage.removeItem('selectedStoreId');
  };

  return (
    <Box  color = "black"  borderTop="1px solid #f8f8f8" bg="#f2f2f2" p="4" >
  <Box maxW="1300px"  mx="auto" py="4">
    <Grid templateColumns="repeat(3, 1fr)" gap={4} alignItems="start">
      
      <Box  textAlign="start" justifySelf="start">
        <Text fontFamily="Lexend" fontWeight="bold" mb="2" fontSize="large">Business Portals</Text>
        <Link fontFamily="Inter" fontSize="lg" href={merchantlink} target="_blank" _hover={{ color: "#B72618" }} mb="1" display="block">
          Merchant Portal
        </Link>
        <Link fontFamily="Inter" fontSize="lg" href={agentlink} target="_blank" _hover={{ color: "#B72618" }} mb="1" display="block">
          Agent Portal
        </Link>
        <Link fontFamily="Inter" fontSize="lg" href={promotionallink} target="_blank" _hover={{ color: "#B72618" }} mb="1" display="block">
          Partner with Us
        </Link>
      </Box>

      <Box marginRight={20} textAlign="start" mx="auto"> 
        <Text fontFamily="Lexend" fontWeight="bold" mb="2" fontSize="lg">Quick Links</Text>
        <Box ml="0"> 
        <Link fontFamily="Inter" fontSize="lg" href="/storelocator" _hover={{ color: "#B72618" }} mb="1" display="block">
          Stores Near Me
        </Link>
          <Link fontFamily="Inter" fontSize="lg" href="/about-us" target="_blank" _hover={{ color: "#B72618" }} mb="1" display="block">
            About Us
          </Link>
          <Link fontFamily="Inter" fontSize="lg" href="/careers" target="_blank" _hover={{ color: "#B72618" }} mb="1" display="block">
            Careers
          </Link>
          <Link fontFamily="Inter" fontSize="lg" href="/faq" target="_blank" _hover={{ color: "#B72618" }} mb="1" display="block">
            FAQ
          </Link>
        </Box>
      </Box>

      <Box  mb="25px"textAlign="end" justifySelf="end">
        <Text fontFamily="Lexend" fontWeight="bold" mb="2" mr="10px" fontSize="lg">Get the App</Text>
        <Flex direction="row" alignItems="center" gap="3"> 
          <Box onClick={openAppStore} _hover={{ cursor: "pointer" }}>
          {/* <Image src={require('../../../assets/icons/Appstore.svg')} /> */}
          <FaApple fontSize="30px" />
           
          </Box>
          <Box mt={1} onClick={openPlayStore} _hover={{ cursor: "pointer" }}>
            {/* <Image src={require('../../../assets/icons/google-play-badge.svg')} /> */}
          <FaGooglePlay fontSize="25px" />

          </Box>
        
        </Flex>

        <Text mt="10px" mr="30px" fontFamily="Lexend" fontWeight="bold" mb="2" fontSize="lg">Follow Us</Text>
        <Flex direction="row" alignItems="start" gap="2">
  <a href="https://www.facebook.com/your-facebook-account" target="_blank" rel="nofollow noopener noreferrer">
    <FaFacebook fontSize="25px" />
  </a>

  <a href="https://www.instagram.com/1800_spirits?igsh=ZTZ2YnViOWRobTlx" target="_blank" rel="nofollow noopener noreferrer">
    <FaInstagram fontSize="25px" />
  </a>
</Flex>


      </Box>
    </Grid>

    <Box mt="4" pt="2" borderTop="1px solid black"></Box>

    <Flex direction="column" align="center" fontFamily="Lexend" fontSize="md" mt="3">
      <Flex direction="row" justify="center" mt="8px" pb="2" alignItems="center">
        <Link fontSize="lg" target="_blank" href="/terms-of-use" _hover={{ color: "#B72618" }}>
          Terms and Conditions
        </Link>
        <Text as="span" mx="1"color="black">
          |
        </Text>
        <Link fontSize="lg" target="_blank" href="/privacy-policy" _hover={{ color: "#B72618" }}>
          Privacy Policy
        </Link>
      </Flex>
      <Flex fontSize="lg" justify="center" pb="1">
        <Text >Copyright &copy; {new Date().getFullYear()}, 1800Spirits</Text>
      </Flex>
    </Flex>
  </Box>
</Box>

);
}

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  VStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Link,
  Text,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { FaApple } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { ImFacebook } from 'react-icons/im';
import { connect } from 'react-redux';
import { getCustomerContactStatus } from '../../../services/user-service';
import { signInPage } from '../../../utils/resources-en';
import VerifyForm from '../verify-form';
import SigninFormWithEmail from './signin-form-email';
import SigninFormWithMobile from './signin-form-mobile';
import SigninFormWithUserId from './signin-form-userId';
import PhoneInput from 'react-phone-input-2';

const { signin, userId, mobileNumber, emailId, signup, forgotPassword, login } =
  signInPage;

const SignInForm = (props) => {
  const {
    fetchAndSetUser,
    fetchAndSetCart,
    switchToSignUp,
    switchToForgotPassword,
    switchToResetPassword,
    onLoginViewClose,
    deliveryAddress,
    cart,
    updateDeliveryAddressStatus,
  } = props;
 
  const [signInState, setSigninState] = useState('signinUserId');
  const [userName, setUserName] = useState('');
  const [userNameInput, setUserNameInput] = useState();
  const [passwordInput, setPasswordInput] = useState();
  const [isNumber ,setIsNumber]=useState(false)
  const [resetPasswordState, setResetPasswordState] = useState('reset');
  const toast = useToast();
  const [inputType, setInputType] = useState('text');
  const [loginLoading, setLoginLoading] = useState(false);
  const [passwordSecure, setPasswordSecure] = useState(false);
  const colorMode = useColorMode()
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const onCloseAlertDialog = () => {
    setIsAlertDialogOpen(false);
  };
  const [passwordAction, setPasswordAction] = useState('');
  function signInStateChange(signInStateValue, userNameValue) {
    setSigninState(signInStateValue);
    setUserName(userNameValue);
  }
  
  const signIn = async ({ username, password }) => {
    try {
      setLoginLoading(true);

      await Auth.signIn({
        username,
        password,
      });

      await Auth.currentAuthenticatedUser();
      const user = await fetchAndSetUser();
      let updateCart;
      if (user) {
        updateCart= await fetchAndSetCart(user.userId);
        toast({
          title: 'Success',
          description: '',
        });
      }
      else {
        toast({
          title: 'Error',
          description:
             'Something went wrong. Please try again',
          status: 'error',
        });

      }


     // navigate('/');
      onLoginViewClose();
      setLoginLoading(false);

      console.log("AfterSignIn::",updateCart,deliveryAddress);

      let tempCart;
      
    
      if(cart)
          tempCart = JSON.parse(JSON.stringify(cart))
      // If Cart exists and DeliveryTo added then redirect to pages after login.
      console.log("AfterSignIn2::",tempCart,cart,(updateCart || cart),deliveryAddress);
      if ((cart && cart.cartShipment.items.length >0) && deliveryAddress && deliveryAddress.address) {
        const contactsRes = await getCustomerContactStatus(
          username,
          deliveryAddress,
        );
       console.log("AfterSignIn3",contactsRes);
        updateDeliveryAddressStatus(contactsRes);
        if (contactsRes.status === 'add') {
          navigate(`/cart/${cart.id}`);
          navigate('/userprofile/deliverycontacts');
         console.log("contactRes");
        }
    
      }
      else
      {
        updateDeliveryAddressStatus(null);
      }
   
    } catch (error) {
      setLoginLoading(false);

      if(error?.message.includes('User does not exist'))
      error.message = "Incorrect username or password."
      if (error.code === 'UserNotConfirmedException') {
        signInStateChange('verification', username);
        
      } 
      if (error.code === 'PasswordResetRequiredException') {
        setIsAlertDialogOpen(true);
      }else {
        toast({
          title: 'Error',
          description:
            error?.message || 'Something went wrong. Please try again',
          status: 'error',
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: { userId: '', password: '', countryCode: '+1' },
    onSubmit: () => {
      signIn({
        username: userNameInput,
        password: passwordInput,
      });
    },
  });
  
 const { values, handleBlur, handleChange, setFieldValue } = formik;
  const handleInputChange = (event) => {
    const firstLetter = event.target.value.charAt(0);
    const isNumber = !isNaN(firstLetter);

    setInputType(isNumber ? 'number' : 'text');
  };

  const handleSocialLogin = async (providerName) => {
    try {
      setLoginLoading(true);
      await Auth.federatedSignIn({
        provider: providerName,
      });
      setLoginLoading(false);
    } catch (err) {
      console.log("GoogleSocialSignInError",err);
    }
  };

  const { handleSubmit } = formik;
  const handleResetPassword = () => {
    setResetPasswordState('reset')
    setPasswordAction('reset')
    onCloseAlertDialog();
    switchToResetPassword();
  };

  return (
    
    <Box
    display="flex"
    width={signInState === 'signinUserId' ? '450px' : '600px'}
    height={signInState === 'signinUserId' ? '650px' : '450px'}
    // width="450px"
    // height="650px"
    padding="48.937px 36.703px"
    // ml="45px"
    flexDirection="column"
    alignItems="center"
    gap="36.703px"
    justifyContent="center"
    flexShrink={0}
    borderRadius="18.352px"
   className='mainBg'
    
    
    // boxShadow="0px 6.117px 9.176px -3.059px rgba(16, 24, 40, 0.03), 0px 18.352px 24.469px -6.117px rgba(16, 24, 40, 0.08)"
  >
   
   
      {signInState === 'signinUserId' ? (
        <Box width="90%" height="100%">
       
          <Stack>
            <h1 className='mainBg' fontFamily="lexend" fontSize="8px" fontStyle="normal"  lineHeight="normal">
               Customer Sign In </h1>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Box>
              <Box>
              <Text className="mainBg" fontFamily="inter" fontSize="10px" fontStyle="normal" fontWeight="700" lineHeight="27.527px" mt="18px"> Username, Email or Phone Number
              </Text>
              {isNumber ? (
          <PhoneInput
          inputProps={{
            // name: 'phone',
            // required: true,
            autoFocus: true
          }}
          className="roundPhoneInput"
          inputStyle={{ width: '100%', borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
          // color="#363636"
          style={{
            color: colorMode === 'dark' ? 'black' : 'black', // Adjust the color based on color mode
            // Adjust the border color based on color mode
          }}
          specialLabel={''}
          countryCodeEditable={false}
          country="us"
          value={values.phoneNumber}
          name="phoneNumber"
          onBlur={handleBlur}
          onChange={(value, country, e, formattedValue,data) => {
            const newValue = e.target.value;
            console.log('nanda',value,e)

            if(isNaN(e.nativeEvent.data)&&(e.nativeEvent?.data)){
              const mobileNuber=value.slice(country.dialCode.length)
              setIsNumber(false)
              setFieldValue('userId',mobileNuber+e.nativeEvent.data)
              setFieldValue('phoneNumber',)
              setUserNameInput(value+e.nativeEvent.data);
            }
            
            else{
            setFieldValue('countryCode',`+${country.dialCode}`) 
            console.log("value218",value,e,newValue,formattedValue,values,country)
            const tempValue = `+${value}`;
            setFieldValue('phoneNumber', tempValue);
            setUserNameInput(tempValue);
            }
          }}
          onKeyDown={(e)=>{
            
            if(e.key=== "Backspace" && (values.phoneNumber===undefined || values.phoneNumber===values.countryCode )  ){
              setIsNumber(false)
              setFieldValue('userId','')
              setFieldValue('phoneNumber',)
              setUserNameInput()
            }
           
          }}     
         
        />
      ) : (
        <Input
          autoFocus='true'
          fontFamily='Inter'
        placeholder='Username, Email or Phone Number'
        _placeholder={{color:"grey"}}
          value={values.userId}
          style={{
            display: 'flex',
            padding: '12.234px',
            alignItems: 'flex-start',
            gap: '12.234px',
            alignSelf: 'stretch',
            borderRadius: '22.939px',
            border: '0.5px solid #B2B2B2',
            marginTop:"5px",
            backgroundColor:' brand.red',
            color:'black'
            
          }}
          onChange={(e)=>{
            const newValue = e.target.value.replace(/\s/g, '');
            //setInputValue(newValue);
           let temp=newValue !== '' && !isNaN(newValue)
           console.log('new@',newValue,temp)

            setIsNumber(newValue !== '' && !isNaN(newValue))
            if(temp){
              setFieldValue('userId','')
              // setFieldValue('phoneNumber',values.countryCode+newValue)
              setFieldValue('phoneNumber',`+${values.countryCode}${newValue}`)
              setUserNameInput(`${values.countryCode}${newValue}`)
              
            }
            else{
            setFieldValue('userId', newValue);
           setUserNameInput(newValue);}}}
           sx={{
            '::placeholder': {
                color: 'gray', // Set the placeholder color to gray
            },
        }}
        />
      )}
    <Text className="mainBg" fontFamily="inter" fontSize="10px" fontStyle="normal" fontWeight="900" lineHeight="27.527px" mt="3px">Password</Text>
              <InputGroup>
    <Input 
   type={!passwordSecure ? 'password' : 'text'}
   placeholder='⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆'
    style={{
      display: 'flex',
      padding: '12.234px',
      // alignItems: 'flex-start',
      alignItems: 'center', 
    //  paddingBottom:'6px',
      gap: '12.234px',
      alignSelf: 'stretch',
      borderRadius: '22.939px',
      marginTop:"5px",
      border: '0.5px solid #B2B2B2',
      background:" #FFF",
      color:'black'
      
    }}
    onChange={(e) => {
      setFieldValue('password', e.target.value);
      setPasswordInput(e.target.value);
          }}
          sx={{
            '::placeholder': {
                color: 'gray', // Set the placeholder color to gray
            },
        }}
    />
    <InputRightElement>
            <Button
              tabIndex="-1"
              color="brand.grey"
              variant="link"
             style={{marginTop:"10px"}}
              onClick={() => {
               setPasswordSecure(!passwordSecure);
              }}
            >
              {passwordSecure ? <FaEye /> : <FaEyeSlash />}
            </Button>
          </InputRightElement>
    </InputGroup>
    
    
    <Stack alignItems="center" mt="10px" justify="space-between" >
                  <Button
                    color="brand.red"
                    variant="link"
                    // alignItems="center"
                    justifyContent="center"
                    fontWeight="normal"
                    mb="10"
                    onClick={() => switchToForgotPassword()}
                  >
                    {forgotPassword}
                  </Button>
                </Stack>
                
                <Box  mt="2px" mb="5px" display="flex" alignItems="center" >
                      <Box flex="1" mb="5px"><Divider borderColor="grey" borderBottomWidth="2px" /></Box>
                    <Text px="3" color="gray.500" fontWeight="semibold" mb="5px"> or</Text>
                     <Box flex="1" mb="5px"> <Divider borderColor="grey" borderBottomWidth="2px" /></Box>
                  </Box>
                 
                  <VStack spacing={2} alignItems="center" mt="25px" mb="20px" gap="10px">
                  <Button
                variant="outline"
                onClick={() =>
                  handleSocialLogin(CognitoHostedUIIdentityProvider.Google)
                }
                borderRadius='12.234px'  border=" 2px solid #B2B2B2"
                background=" #FFF"
                boxShadow="0px 1.529px 3.059px 0px rgba(16, 24, 40, 0.05)"
                w="full"  >
                <FcGoogle  color="brand.grey"className="socialIcon" />
                <Text color=" #1E1E1E" fontFamily="inter" fontSize="13px" fontStyle="normal" fontWeight="500" lineHeight="27.527px" ml={2}
                >Sign in with Google</Text>
              </Button>
            <Button
                variant="outline"
                onClick={() =>
                  handleSocialLogin(CognitoHostedUIIdentityProvider.Apple)
                }
                borderRadius='12.234px'
                w="full"
                fontSize="13px"
                border=" 2px solid #B2B2B2"
                background=" #FFF"
                boxShadow="0px 1.529px 3.059px 0px rgba(16, 24, 40, 0.05)"
                mt="10px"
              >
                <FaApple  style={{ color:"#B0B0B0"}}className="socialIcon" />
                <Text color=" #1E1E1E" fontFamily="inter" fontSize="13px" fontStyle="normal" fontWeight="500" lineHeight="27.527px" ml={2}
                >Sign in with Apple</Text>
              </Button>
              
              {/* Facebook */}
              {/* <Button
                variant="outline"
                onClick={() =>
                  handleSocialLogin(CognitoHostedUIIdentityProvider.Facebook)
                }
                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              >
                <ImFacebook className="socialIcon" color="#385C8E" />
              </Button> */}
             
            </VStack>
           
                </Box>
                <Box ml="3" w="full" mt="7px"  marginLeft="1px">
                    <Button
                    
                      isLoading={loginLoading}
                      type="submit"
                      w="full"
                      _hover={{ background: 'brand.red' }}
                      data-cy="loginBtn"
                       borderRadius='12.234px' padding="15.293px 0px" border="1.529px solid #B72618" 
                background="#B72618" boxShadow=" 0px 1.529px 3.059px 0px"
                fontFamily="inter"
                    >
                      {login}
                    </Button>
                  </Box>
                  {/* <Box>
                    <Button
                      variant="cancel-button"
                      onClick={() => switchToSignUp()}
                      data-cy="signUpBtn"
                    >
                      {signup}
                    </Button>
                  </Box> */}
                  <Text  fontFamily='Inter' mt="8" textAlign="center" color="gray.600">
                     Don't have an account? {' '}
                    <Link  ml="4px" fontWeight={"bold"} onClick={() => switchToSignUp()} data-cy="signUpBtn"
                    color = {colorMode.colorMode == "light" ? "black" : "white"}
                    >
                     {signup}
                     </Link>
                    </Text>
                    <Text textAlign="center" mt="6">
                       <Button
                        variant="cancel-button"
                        data-cy="skipNowBtn"
                        fontFamily="inter"
                        
                        onClick={() => onLoginViewClose()}
                        padding="0" // Set padding to 0 to remove padding
                        color="#B72618" // Set the color to red
                        border="none" 
                       >
                           Skip
                        </Button>
                        </Text>
                      
                       
             
                {/* <Stack align="flex-end" justify="space-between">
                  <Button
                    color="brand.red"
                    variant="link"
                    fontWeight="normal"
                    onClick={() => switchToForgotPassword()}
                  >
                    {forgotPassword}
                  </Button>
                </Stack> */}
                {/* <Flex pb="8" direction="row"> */}
                  {/* <Box flexGrow="2">
                    <Button
                      variant="cancel-button"
                      data-cy="skipNowBtn"
                      onClick={() => onLoginViewClose()}
                    >
                      Skip Now
                    </Button>
                  </Box> */}
                  
                 
                {/* </Flex> */}
              
            </Box>
           
          </form>
         
        </Box>
      ) : signInState === 'verification' ? (
        <VerifyForm
          username={userName}
          switchToSignIn={() => setSigninState('signinUserId')}
          verifyFromSignIn="true"
        />
      ) : null}
    <AlertDialog isOpen={isAlertDialogOpen} onClose={onCloseAlertDialog}>
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader>Password Reset Required</AlertDialogHeader>
        <AlertDialogBody>
          You need to reset your password before signing in.
        </AlertDialogBody>
        <AlertDialogFooter>
        <Flex justify="space-between" width="100%">
        <Button variant='cancel-button' onClick={onCloseAlertDialog}>
            Close
          </Button>
        <Button colorScheme="red" onClick={handleResetPassword}>
              Reset Password
            </Button>
            </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
  </Box>

  );
};

const stateMapper = (state) => ({
  deliveryAddress: state.deliveryAddress?.deliveryAddress,
  cart: state.cart.cart,
});

const dispatchMapper = (dispatch) => ({
  fetchAndSetUser: dispatch.auth.fetchAndSetUser,
  fetchAndSetCart: dispatch.cart.fetchAndSetCart,
  updateDeliveryAddressStatus:
    dispatch.deliveryAddress.updateDeliveryAddressStatus,
});

export default connect(stateMapper, dispatchMapper)(SignInForm);

import { API, graphqlOperation, toast } from 'aws-amplify';
import { utimes } from 'fs';
import {
  calculateTaxQuery,
  cancelPaymentIntentQuery,
  createOrderQuery,
  updateOrderShipment,
} from '../graphql/mutations';
import { getOrder, listOrdersByUserId,listOrderShipmentsByUserId, searchPriceNAvailabilitys } from '../graphql/queries';
import { graphql } from '../utils/api';
import awsExports from '../aws-exports';
import { useToast } from '@chakra-ui/react';

const defaultStatusHistory = {
  fromStatus: 'fromStatus',
  toStatus: 'toStatus',
  updatedBy: 'updatedBy',
};

async function getOrderDetails(orderId) {
  const graphqlQuery = graphqlOperation(getOrder, {
    id: orderId,
  });
  const res = await graphql(graphqlQuery);
  const orderDetails = res.data.getOrder;
  return orderDetails;
}
async function createOrder(cart, user) {
  try{
  const createOrderInput = {
    cartId: cart.id,
    channel: 'direct',
    orderShipment: [],
    orderStatus: 'Created', // enum: Created,Open,Closed
    totalAmount: 0,
    totalDeliveryCharges: 0,
    totalDiscount: 0,
    totalProductAmount: 0,
    totalTaxAmount: 0,
    totalTipAmount: 0,
    transactionId: cart.id, // transactionId same as cart if still payment gateway integration is completed
    userAgent: cart.userAgent,
    userId: user.userId,
    clientInterface: 'website'
  };

  //getting the Customer delivery Contact Phone number abd email 
  const customerContactDeails = user?.customerContact?.items?.filter(item => item?.id === user?.deliveryToAddress?.customerContactId);

  console.log("customerContactDeails47::",customerContactDeails,user);
  const cartShipments = cart.cartShipment.items;
  cartShipments.forEach((eachCartShipment) => {

    console.log("EachShipment50::",eachCartShipment);
    const tempOrderShipment = {
      clientInterface: "website",
      assignedStoreId: eachCartShipment.assignedStoreId,
      assignedStoreName: eachCartShipment.assignedStoreName,
      phoneNumber: customerContactDeails[0] ? customerContactDeails[0].phoneNumber: user?.customerContact?.items[0]?.phoneNumber, 
      firstName: customerContactDeails[0] ? customerContactDeails[0].firstName: user?.customerContact?.items[0]?.firstName, 
      lastName: customerContactDeails[0] ? customerContactDeails[0].lastName: user?.customerContact?.items[0]?.lastName, 
      email: customerContactDeails[0] ? customerContactDeails[0].email: user?.customerContact?.items[0]?.email,
      deliveryAddress: {
        addrLine1: eachCartShipment.deliveryAddress.addrLine1,
        addrLine2: eachCartShipment.deliveryAddress.addrLine2,
        city: eachCartShipment.deliveryAddress.city,
        state: eachCartShipment.deliveryAddress.state,
        country: eachCartShipment.deliveryAddress.country,
        postCode: eachCartShipment.deliveryAddress.postCode,
        latitude: eachCartShipment.deliveryAddress.latitude,
        longitude: eachCartShipment.deliveryAddress.longitude,
      },
      deliveryType: eachCartShipment.deliveryType,
      orderLineItems: [],
      stripeCustomerId: eachCartShipment.stripeCustomerId,
      paymentIntentId: eachCartShipment.paymentIntentId,
      paymentIntentRes: eachCartShipment.paymentIntentRes,
      shipmentStatus: 'Placed', // enum: Placed,Assigned,Accepted,Picked,Delivered,
      statusHistory: defaultStatusHistory,
      subOrderAmount: eachCartShipment.subOrderAmount,
      userIdStatus: `${eachCartShipment.userId}_Created`,
      subTotalDeliveryCharges: eachCartShipment.subTotalDeliveryCharges,
      subTotalMerchantCharges: eachCartShipment.subTotalMerchantCharges,
      subTotal1800platformfee: eachCartShipment.subTotal1800platformfee,
      couponCodeDiscount: eachCartShipment.subTotalDiscount,
      subTotalCardProcessingFee: eachCartShipment.subTotalCardProcessingFee,
      merchantAccountIdStatus: eachCartShipment.merchantAccountIdStatus,
      subTotalAmount: eachCartShipment.subTotalAmount,
      shipmentLevelReplacement: eachCartShipment.shipmentLevelReplacement || "Call me if Product/Quantity unavailable",
      giftInstructions: eachCartShipment.giftInstructions,
      shipmentLevelReplacementFee: eachCartShipment.shipmentLevelReplacementFee,
      totalPayableAmount: eachCartShipment.totalPayableAmount,
      subTotalDiscount: eachCartShipment.subTotalDiscount,
      merchantConvenienceFee: 3.99 - eachCartShipment.subTotal1800platformfee,
      subTotalProductAmount: eachCartShipment.subTotalProductAmount,
      autoDiscountProductTotal: eachCartShipment.discountSubTotalProductAmount || eachCartShipment.subTotalProductAmount,
      saleDiscountProductTotal: eachCartShipment?.subTotalSaleAmount || 0,
      discount: eachCartShipment.discount ,
      isDoordash:eachCartShipment.isDoordash,
      appliedPromoCode: eachCartShipment.appliedPromoCode || "",
      promoCode_codeType: eachCartShipment.appliedPromoCodeCodeType|| "",
      expiryDateTime: eachCartShipment.expiryDateTime|| 0,
      autoDiscount: eachCartShipment.autoDiscount ,
      saleDiscount: eachCartShipment.saleDiscount ,
      subTotalTax: eachCartShipment.subTotalTax,
      subTotalTipAmount: eachCartShipment.subTotalTipAmount,
      scheduledDeliveryDt: eachCartShipment.scheduledDeliveryDt ||"Today",
      scheduledTimeSlot: eachCartShipment.scheduledTimeSlot || "ASAP",
      updatedBy: eachCartShipment.userId || user.userId,
      userId: eachCartShipment.userId || user.userId,
    };

    if (eachCartShipment.deliveryType === "Delivery") {
      tempOrderShipment.transportationType = (eachCartShipment.isDoordash || (eachCartShipment.deliveryScope === "Delivery_Partner" && eachCartShipment.deliveryType === "Delivery")) ? "Doordash" : "Own_Driver";
    }

    eachCartShipment.lineItems.forEach((eachLineItem) => {
      console.log("EachLineItemOrder:::",eachLineItem);
      tempOrderShipment.orderLineItems.push({
        prodShortDesc: eachLineItem.prodShortDesc,
        upc: eachLineItem?.upc,
        productId: eachLineItem.productId,
        productName: eachLineItem.productName,
        qtyPurchased: eachLineItem.qtyPurchased,
        size: eachLineItem.size,
        totalPrice: eachLineItem.totalPrice,
        unitPrice: eachLineItem.unitPrice,
        uom: eachLineItem.uom,
        storeItemId: eachLineItem.storeItemId,
        storeItemDesc: eachLineItem.storeItemDesc,
        storePosName: eachLineItem.storePosName,
        storeSizeUom: eachLineItem.storeSizeUom,
        prodCategory: eachLineItem.prodCategory,
        storeDisplayQty: eachLineItem.storeDisplayQty,
        imageSrc: eachLineItem.imageSrc,
        attributes: eachLineItem.attributes,
        salePrice: eachLineItem?.salePrice,
        availableQty: eachLineItem?.availableQty,
        containerType: eachLineItem?.containerType,
	      packUnits: eachLineItem?.packUnits,
        storeItemQty: eachLineItem?.storeItemQty
        // upcId: eachLineItem.upcId
      });
    });

    createOrderInput.orderShipment.push(tempOrderShipment);
  });

  try {
    const graphqlQueryProduct = graphqlOperation(createOrderQuery, {
      input: createOrderInput,
    });
    const res = await API.graphql({
      ...graphqlQueryProduct,
    });

    return {
      success: true,
      orderId: res.data.createOrder.id,
    };
  } catch (e) {
    return { success: false, error: e };
  }
}
catch(error)
{
  console.log("Error on createOrder::",error);
  const cartShipments = cart.cartShipment.items;
  let cancelIndex =0;
  for(let i in cartShipments) {

  const cancelPaymentIntent = await graphql(
    graphqlOperation(cancelPaymentIntentQuery, {
      input: {
        cancelPaymentIntentId: cartShipments[i].paymentIntentId,
        stripeCustomerId: cartShipments[i].stripeCustomerId,
        assignedStoreId: cartShipments[i].assignedStoreId
      },
    }),
  );
  console.log("cancelPaymentIntent", cancelPaymentIntent);
  if (cancelPaymentIntent.data.cancelPaymentIntent.success) {
    console.log("cancelPaymentIntent::",cancelIndex);
    cancelIndex = cancelIndex+1;
  }
 
}
console.log("cancelPaymentIntentToast::",cancelIndex,cartShipments.length);
if (cancelIndex == cartShipments.length) {
  return { success: false, error: error };
}

}
}

async function getOrdersByUserId(userIdStatus, nextToken, limit,filter) {
  //const ModelOrderFilterInput = filter;
  const graphqlQuery = graphqlOperation(listOrderShipmentsByUserId, {
    userIdStatus,
    nextToken,
    limit,
    filter,
  });
  const res = await graphql(graphqlQuery);
  const ordersList = res.data.listOrderShipmentsByUserId;
  console.log("OrderList146::",ordersList);
  return ordersList;
  console.log('res@31424',res)
}

async function calculateTaxes(tempCartShipments) {
  try {
    const graphqlQueryProduct = graphqlOperation(calculateTaxQuery, {
      input: tempCartShipments,
    });
    const res = await API.graphql({
      ...graphqlQueryProduct,
    });

    if (res?.data.calculateTax?.items.length > 0) {
      return {
        success: true,
        calculatedTaxes: res.data.calculateTax.items,
      };
    }
    return {
      success: false,
    };
  } catch (e) {
    return { success: false, error: e };
  }
}

async function updateShipment(updateOrderShipmentInput) {
  try {
    const response = await graphql(
      graphqlOperation(updateOrderShipment, {
        input: { ...updateOrderShipmentInput },
      })
    );

    // Assuming the response structure contains the id in the returned data
    const id = response?.data?.updateOrderShipment?.id;
    const updateOrderShipmentResponse= response?.data?.updateOrderShipment;

    return { success: true, id: id ,updateOrderShipmentResponse:updateOrderShipmentResponse };
  } catch (e) {
    return { success: false, error: e };
  }
}


function createCartfromOrder(eachCartShipment,lineItem)
{
try{
  const tempCartShipment = {
    assignedStoreId: eachCartShipment.assignedStoreId,
    assignedStoreName: eachCartShipment.assignedStoreName,
    // phoneNumber: customerContactDeails ? customerContactDeails[0].phoneNumber: user?.customerContact?.items[0].phoneNumber, 
    // firstName: customerContactDeails ? customerContactDeails[0].firstName: user?.customerContact?.items[0].firstName, 
    // lastName: customerContactDeails ? customerContactDeails[0].lastName: user?.customerContact?.items[0].lastName, 
    // email: customerContactDeails ? customerContactDeails[0].email: user?.customerContact?.items[0].email,
    deliveryAddress: {
      addrLine1: eachCartShipment.deliveryAddress.addrLine1,
      addrLine2: eachCartShipment.deliveryAddress.addrLine2,
      city: eachCartShipment.deliveryAddress.city,
      state: eachCartShipment.deliveryAddress.state,
      country: eachCartShipment.deliveryAddress.country,
      postCode: eachCartShipment.deliveryAddress.postCode,
      latitude: eachCartShipment.deliveryAddress.latitude,
      longitude: eachCartShipment.deliveryAddress.longitude,
    },
    deliveryType: eachCartShipment.deliveryType,
    lineItems: [prepareLineItemfromOrder(lineItem)],
    subOrderAmount: eachCartShipment.subOrderAmount,
    subTotalDeliveryCharges: eachCartShipment.subTotalDeliveryCharges,
    subTotal1800platformfee: eachCartShipment.subTotal1800platformfee,
    subTotalCardProcessingFee: eachCartShipment.subTotalCardProcessingFee,
    subTotalAmount: eachCartShipment.subTotalAmount,
    subTotalDiscount: eachCartShipment.subTotalDiscount,
    subTotalProductAmount: eachCartShipment.subTotalProductAmount,
    discountSubTotalProductAmount: eachCartShipment.discountSubTotalProductAmount || eachCartShipment.subTotalProductAmount,
    subTotalSaleAmount: eachCartShipment?.subTotalSaleAmount || 0,
    userId: eachCartShipment.userId,
  };

  return tempCartShipment;

}
catch(e)
{
  console.log("Error in creating Cart from Order",e);
}
}

function prepareLineItemfromOrder(eachLineItem){

  console.log("lineItem from Order::",eachLineItem);
  try{

    const cartLineItem = {
      prodShortDesc: eachLineItem.prodShortDesc,
      productId: eachLineItem.productId,
      productName: eachLineItem.productName,
      qtyPurchased: eachLineItem.qtyPurchased,
      size: eachLineItem.size,
      totalPrice: eachLineItem.totalPrice,
      unitPrice: eachLineItem.unitPrice,
      uom: eachLineItem.uom,
      storeItemId: eachLineItem.storeItemId,
      storeItemDesc: eachLineItem.storeItemDesc,
      storePosName: eachLineItem.storePosName,
      storeSizeUom: eachLineItem.storeSizeUom,
      prodCategory: eachLineItem.prodCategory,
      storeDisplayQty: eachLineItem.storeDisplayQty,
      imageSrc: eachLineItem.imageSrc,
      attributes: eachLineItem.attributes,
      // upcId: eachLineItem.upcId
    };

    console.log("CartlineItem from Order::",cartLineItem);

    return cartLineItem;
  }
  catch(error)
  {
    console.log("Error in creating lineItem from order",error);
  }

}

async function handlePNAData(upcIds,cartObj,distance,lat,lon){

 console.log("upcIDs and CartObj in PNA:::",upcIds,cartObj,distance,lat,lon);
try{

  const searchStoresByProductIdQuery = graphqlOperation(
    searchPriceNAvailabilitys,
    {
      distance,
      lat,
      lon,
      id: upcIds ,
      // showAll: (showAll !== undefined) ? showAll : true
    },
  );
  const pNAData = await API.graphql({
    ...searchStoresByProductIdQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });

  console.log("response1:::",pNAData);

  // const pNAresp = res1?.searchPriceNAvailabilitys?.items;
  if(pNAData?.data?.searchPriceNAvailabilitys?.items) {

   const updatedCartShipment = {
      ...cartObj.cartShipment,
      items: cartObj.cartShipment.map((item) => {
        const matchingPnaStores = pNAData.data.searchPriceNAvailabilitys.items.filter(
          (pnaItem) => pnaItem.storeId === item.assignedStoreId
        );

        if (matchingPnaStores.length > 0) {
          const updatedLineItems = item.lineItems.map((lineItem) => {
            const matchingPnaItem = matchingPnaStores.find(
              (pnaStore) => pnaStore.id === lineItem.upcId
            );

            if (matchingPnaItem) {
              return {
                ...lineItem,
                availableQty: matchingPnaItem.availableQty,
                unitPrice: matchingPnaItem.price,
                salePrice: matchingPnaItem.salePrice,
              };
            } else {
              return lineItem; // No match found for this lineItem, keep it as is
            }
          });

          return {
            ...item,
            lineItems: updatedLineItems,
          };
        } else {
          return item; // No matching store found, keep the original item
        }
      }),
    };


  const updatedCartObj = {
    ...cartObj,
    cartShipment: updatedCartShipment.items,
  };

  console.log("updatedCartObj334:::",updatedCartObj);
  return updatedCartObj;
}

}
catch(error)
{
  console.log("error in getting PNAData277::",error);
}

}

export {
  getOrderDetails,
  createOrder,
  getOrdersByUserId,
  calculateTaxes,
  updateShipment,
  createCartfromOrder,
  prepareLineItemfromOrder,
  handlePNAData,
};

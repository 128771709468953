import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  useColorMode,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';

export default function AgeLimit({ isOpen, setAgeLimitOpen, setLoginOpen }) {
  const [underAgeInfo, setUnderAgeInfo] = useState();
  const { colorMode } = useColorMode();
  const [bottomPadding, setBottomPadding] = useState("64px");
  const handleEnableNotifications = async () => {
    let permission = await Notification.requestPermission()
    console.log('permissioin', permission)
    }
  const redirectToGoogle = (e) => {
    const appScheme = "https://www.google.com";
   
    
    const appLink = document.createElement("a");
      appLink.href = appScheme;
  
      // Add an event listener to handle the app opening attempt
      appLink.addEventListener("click", (event) => {
        // Prevent the default behavior of the link (to prevent navigation)
        event.preventDefault();
  
        // Try to open the app
        window.location.href = appScheme;
      });
  
      // Trigger the click event on the link
      appLink.click();
  
  };
  return (
    <Modal
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="xl"
     
    >
      <ModalOverlay padding=" 302px 469px 302px 470px" />
      
      <ModalContent
        borderRadius="12.234px"
        style={{ width: "80%", maxWidth: "789px", height:"513px" }}
        width={{ base: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' }}
        height="100%"
      >
        <ModalBody padding={`64px 64px ${bottomPadding} 64px`} alignItems="center"  justifyContent="center" className="blockBg"   color="red"   borderRadius="12.234px"  
        base= '4' md= '8' 

>
  
          <Flex direction="column"   >
          <Box  borderRadius="12.234px">
            {/* <Flex justifyContent="flex-end">
              <Box w="35%" h="5px" bg="brand.red" roundedBottomLeft="md" />
            </Flex> */}
            <VStack textAlign="center" spacing="3"  h="400px" gap="32px" >
              <Image
                src={require('../../../assets/logo/Logo.svg')}
                width=" 237px"
                height=" 85px"
              />
              {/* <Text color=" var(--text-color, #252B42)" textAlign="center" fontFamily="lexend" fontSize="64px" fontStyle="normal" fontWeight="600" lineHeight="normal"letterSpacing="-1.28px">

                Welcome to 1800Spirits
              </Text> */}
              <Text  as="h3"  color={colorMode==="dark"?"white":"#252B42"} textAlign="center" fontFamily="lexend"  fontSize={{ base: '24px', md: '32px', lg: '48px' }} fontStyle="normal" fontWeight="600" lineHeight="normal"letterSpacing="-1.28px">
                Are you 21 Years old?
              </Text>
              <Text  color={colorMode==="dark"?"white":"#1E1E1E"} textAlign="center" fontFamily="lexend" fontSize={{ base: '14px', md: '16px', lg: '22px' }} fontStyle="normal" fontWeight= "400" lineHeight="140%">


              You must be 21 years old to order alcohol through our platform.
              </Text>
              <Flex pb="5" direction={{ base: 'column', md: 'row' }} pt="5">
                <Box mb={{ base: '3', md: '0' }}>
                  <Button
                   
                    variant="cancel-button"
                    // borderColor="brand.red"
                    color=" #B72618" fontFamily="inter" fontSize={{ base: '14px', md: '16px', lg: '20px' }} fontStyle="normal" fontWeight="600"
                    borderRadius="12.234px"
                    border=" 1.529px solid #B72618"
                    justifyContent="center"
                    // box-shadow=" 0px 1.529px 3.059px 0px rgba(16, 24, 40, 0.05)"
                    padding=" 15.293px 12px"
                    onClick={() => {
                      setUnderAgeInfo('Thank you for your Honesty.');
                      setBottomPadding("32px");
                      redirectToGoogle()
                    }}
                  >
                   No, I am not 21
                  </Button>
                </Box>
                <Box ml={{ base: '0', md: '3' }}>
                  <Button
                    type="submit"
                    color=" #FFF" fontFamily="inter" fontSize="21.41px" fontStyle="normal" fontWeight="600px" 
                    padding=" 15.293px 12px" justifyContent="center" alignContent="center" borderRadius="12.234px"
                    border=" 1.529px solid #B72618"
                    
                    background=" #B72618"
                    
                    box-shadow=" 0px 1.529px 3.059px 0px rgba(16, 24, 40, 0.05)"

                    _hover={{ background: 'brand.red' }}
                    onClick={() => {
                      setLoginOpen(false);
                      setAgeLimitOpen(false);
                      // handleEnableNotifications()
                      localStorage.setItem('showAgeLimitPopup', 1); 
                    }}
                    data-cy="ageLimitYesbtn"
                  >
                    Yes, I am 21 Years Old
                  </Button>
                </Box>
              </Flex>
              
            </VStack>
           <Text 
            mt="48px"
            
            //fontSize="{{ base: '16px', md: '22px', lg:'22px' }}"
           //mt={{ base: '3', md: '3'}}  
           color={colorMode==="dark"?"white":"#1E1E1E"}  fontFamily="lexend"  fontweight="400" fontSize="22px"  fontStyle="normal" textAlign="center">{underAgeInfo}</Text>
            {/* <Box
              w="35%"
              h="5px"
              bg="brand.red"
              roundedTopRight="md"
              alignItems="flex-end"
            /> */}
             </Box>
          </Flex>
         
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

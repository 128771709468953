import awsExports from '../aws-exports';
export default class Product {

  constructor(obj) {
    console.log("obj.remainingAttributes::",obj.remainingAttributes,obj.remainingAttributes.find((attr)=>attr?.imageFile!=null)?.imageFile)
    this.id = obj.remainingAttributes ? obj.remainingAttributes[0].id : obj.id; ;
    this.title = obj.remainingAttributes ? obj.remainingAttributes[0].prodFullName : obj.prodFullName;
    this.productName = obj.remainingAttributes ? obj.remainingAttributes[0].prodFullName : obj.prodFullName;
    this.displayName = obj.remainingAttributes ?( obj.remainingAttributes[0].prodDisplayName ? obj.remainingAttributes[0].prodDisplayName : obj.remainingAttributes[0].prodFullName) : obj.prodDisplayName;
    this.storePosName = obj.remainingAttributes ? obj.remainingAttributes[0].productName : obj.prodDisplayName;
    this.storeSizeUom = obj.remainingAttributes ? obj.remainingAttributes[0].storeSizeUom : '';
    this.categoryName = obj.remainingAttributes ? obj.remainingAttributes[0].prodCategory : obj.prodCategory;
    this.prodCategory = obj.remainingAttributes ? obj.remainingAttributes[0].prodCategory : obj.prodCategory;
    this.imageSrc = obj.remainingAttributes
    ? obj.remainingAttributes.find((attr)=>attr.imageFile!=null)?.imageFile
        ? (/^\d{14}-/.test(obj.remainingAttributes.find((attr)=>attr?.imageFile!=null).imageFile)
            ? `${awsExports.aws_s3_imagesUrl}/salsify/${obj.remainingAttributes.find((attr)=>attr?.imageFile!=null).imageFile}`
            : `${awsExports.aws_s3_imagesUrl}/product/${obj.remainingAttributes.find((attr)=>attr?.imageFile!=null).imageFile}`
          )
        : null
    : obj.imageFile
        ? (/^\d{14}-/.test(obj.imageFile)
            ? `${awsExports.aws_s3_imagesUrl}/salsify/${obj.imageFile}`
            : `${awsExports.aws_s3_imagesUrl}/product/${obj.imageFile}`
          )
        : null;

    // this.description = obj.prodName;
    // this.longDescription = obj.prodName;
    // this.region = obj.region;
    this.container = obj.container;
    //this.storeItemQty = obj.attributes ? obj.attributes.storeItemQty : obj.packUnits;
    this.availability = obj.isAvailableForOnline ? 'available' : 'Not available';
    this.region = obj.remainingAttributes ? obj.remainingAttributes[0].region : obj.region;
    this.feature = obj.remainingAttributes ? obj.remainingAttributes[0].feature : obj.feature;
    this.rawMaterials = obj.remainingAttributes ? obj.remainingAttributes[0].rawMaterials : obj.rawMaterials;
    this.aboutProducer = obj.remainingAttributes ? obj.remainingAttributes[0].aboutProducer : obj.aboutProducer;
    this.producer = obj.remainingAttributes ? obj.remainingAttributes[0].producer : obj.producer;
    this.productDescription = obj.remainingAttributes ? obj.remainingAttributes[0].productDescription : obj.productDescription;
    this.storeDisplayQty = obj.remainingAttributes ? obj.remainingAttributes[0].storeDisplayQty : obj.storeDisplayQty;
    this.abv = obj.remainingAttributes ? obj.remainingAttributes[0].abv : obj.abv;
    this.price = obj.price;
    this.packUnits = obj.remainingAttributes ? obj.remainingAttributes[0].packUnits : obj.packUnits;
    this.sizeUom = obj.attributes ? obj.attributes.sizeUom : obj.sizeUom;
    this.sizeUom1 = obj.attributes ? obj.attributes.sizeUom1 : obj.sizeUom;
    this.country = obj.attributes ? obj.attributes.country : obj.country;
    this.size = obj.attributes ? obj.attributes.size : obj.size;
    this.uom = obj.attributes ? obj.attributes.uom : obj.uom;
    this.age = obj.attributes ? obj.attributes.age : obj.age;
    this.containerType =  obj.attributes ? obj.attributes.containerType : obj.containerType;
    this.storeItemQty = obj.attributes ? obj.attributes.storeItemQty : obj.packUnits ;
    this.rating = '5';
    this.reviewCount = '9999';
    this.brandLine =  obj.remainingAttributes ? obj.remainingAttributes[0].brandLine : obj.brandLine;
    this.manufacturer = obj.manufacturer;
    // this.seoName = '';
    // this.redirectUrl = `/product/${this.id}`;
    this.remainingAttributes =  obj.remainingAttributes || null;
    this.attributes = obj.attributes || null;

  }
}

import { API, graphqlOperation } from 'aws-amplify';
import awsExports from '../aws-exports';
import Product from '../entities/product';
import ProductGroup from '../entities/productGroup';
import {
  searchProducts,
  searchProductsByCategory,
  searchStore,
  searchStoresByProductId,
  searchProductsGroups,
  searchProductsCatalogue,
  searchProductsMaster,
  searchPriceNAvailabilitys,
  searchPriceNAvailabilityQueries
} from '../graphql/queries';
// let countryFlag = false;


async function search(searchText,searchCategory,countryFlag) {
  let fieldFilters;
  let searchType;
  if (!countryFlag){
  if (searchCategory=="All"){
  fieldFilters = {or:[{ 
    prodName: { match: { fuzziness: "AUTO", operator: "AND", query: searchText }},
    prodFullName: { match: { fuzziness: "AUTO", operator: "AND", query: searchText } } 
    }]};
  searchType = "keyword";
  }
  else {
    fieldFilters = { 
      prodCategory: {"eq": searchCategory},
      prodFullName: {"eq": searchText}
    };
  searchType = "categoryKeyword";
    }
  }
  else {
    if(searchCategory=="Country"){
    fieldFilters = {
      country: {matchPhrase: searchText}
    };
    searchType= "country";
  }
    else{
      fieldFilters = {
      
       country: {matchPhrase: searchText}, 
       prodCategory: {matchPhrase: searchCategory.replace('-Country','')}
        
      };
      searchType= "countryCategory";

    }
    // console.log("countryFlag:::",countryFlag);
  }
  // Product Search
  const SearchableProductFilterInput = {
    // filter: { prodFullName: { match: { fuzziness: "AUTO", operator: "AND", query: searchText } } },
    filter: fieldFilters
    //   or: [{
    //   prodName: { match: { fuzziness: "AUTO", operator: "AND", query: searchText } },
    //   prodFullName: { match: { fuzziness: "AUTO", operator: "AND", query: searchText } } 
    // }],
   
    // searchType: 'categoryBeer',
  };
  // const prodCategoryFilter = {
  //   filter: { prodCategory: { match: {query: searchCategory } } },
  // };
  const graphqlQueryProduct = graphqlOperation(searchProducts, {
    ...SearchableProductFilterInput,
    limit: 10,
    searchType, 
    sort: {
      "direction": "asc",
      "field": "prodFullName"
    }
    // searchFor: suggestions, 
  });
  const resProducts = await API.graphql({
    ...graphqlQueryProduct,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const products = [];
  if(resProducts.data.searchProducts){
  resProducts.data.searchProducts.items.forEach((eachResult) => {
    products.push(new Product(eachResult));
  });
}
  // console.log('resProducts',resProducts.data.searchProducts.items[0].prodFullName);

  // Category Search
  // const SearchableCategoryFilterInput = {
  //   filter: { prodCategory: { matchPhrase: searchText } },
  // };
  // const graphqlQueryCategory = graphqlOperation(searchProducts, {
  //   ...SearchableCategoryFilterInput,
  //   limit: 10,
  // });
  // const resCategory = await graphql(graphqlQueryCategory);
  // const categories = [];
  // resCategory.data.searchProducts.items.forEach((eachResult) => {
  //   categories.push(new Product(eachResult));
  // });

  return {
    products,
    // categories,
  };
}

async function searchGroups(searchText,searchCategory,countryFlag) {
  let fieldFilters;
  if (!countryFlag){
  if (searchCategory=="All"){
  fieldFilters = {or:[{ 
    brandLine: { match: { operator: "AND", query: searchText }},
    prodFullName: { match: { operator: "AND", query: searchText } } 
    }]};
  }
  else {
    fieldFilters = { 
      prodCategory: {"eq": searchCategory},
      prodFullName: {match: { operator: "AND", query: searchText }}
    };
    }
  }
  else {
    if(searchCategory=="Country"){
    fieldFilters = {
      country: {matchPhrase: searchText}
    };
  }
    else{
      fieldFilters = {
      
       country: {matchPhrase: searchText}, 
       prodCategory: {matchPhrase: searchCategory.replace('-Country','')}
        
      };

    }
    // console.log("countryFlag:::",countryFlag);
  }
  

  const graphqlQueryProduct = graphqlOperation(searchProductsGroups, {
    
    // filter: {prodFullName: {matchPhrase: searchText}},

    filter : fieldFilters,
    limit: 13    
  //{or: [
  //     {
  //       prodFullName: {
  //         matchPhrase: searchText
  //       }
  //     },
  //     {
  //       brandLine: {
  //         matchPhrase: searchText
  //       }
  //     }
  //   ]
  // }
  });
  const resProducts = await API.graphql({
    ...graphqlQueryProduct,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const data = resProducts.data.searchProductsGroups;
  // const products = resProducts.data.searchProductsMaster.FullName ;
  // const brands = resProducts.data.searchProductsMaster.Brand ;
  // const items = resProducts.data.searchProductsMaster.items
//   if(resProducts.data.searchProductsMaster){
//   resProducts.data.searchProductsMaster.items.forEach((eachResult) => {
//     products.push(new ProductGroup(eachResult));
//   });
// }


// Iterate through array B
// data.FullName.forEach((objB) => {
//   const { key } = objB;
//   const matchingObjC = data.items.find((objC) => objC.prodFullName === key); // Find the first match in array C based on b1 value
//   if (matchingObjC) {
//     objB.prodCategory = matchingObjC.prodCategory; // Attach the corresponding c2 value to a new attribute b3 in array B
//   }
// });

// console.log('data.b',data);

  return {
    // products,
    // categories,
    // brands
    data
  };
}
async function searchPnA(searchText,searchCategory,countryFlag, lat, lon,storeName,storeId) {
  let fieldFilters;
  if (!countryFlag){
  if (searchCategory=="All"){
  fieldFilters = {or:[{ 
    brandLine: { match: { fuzziness: "AUTO", operator: "AND", query: searchText }},
    prodFullName: { match: { fuzziness: "AUTO", operator: "AND", query: searchText } } 
    }]};
  }
  }
  else {
    if(searchCategory=="Country"){
    fieldFilters = {
      country: {matchPhrase: searchText}
    };
  }
    else{
      fieldFilters = {
      
       country: {matchPhrase: searchText}, 
       prodCategory: {matchPhrase: searchCategory.replace('-Country','')}
        
      };

    }
    // console.log("countryFlag:::",countryFlag);
  }
  
  console.log("lat",lat, lon);
  const graphqlQueryProduct = graphqlOperation(searchPriceNAvailabilityQueries, {
    
    filter: {prodFullName: {eq: searchText }} ,
    ...(storeName && { bucketFilter: { storeName: [storeName] } }),
   // filter : searchText,
    limit: 13,
    distance: 20,
    sort: {
      direction: "asc",
      field: "prodFullName"
    },  
    lat: lat|| 40.73053,
    lon: lon||-74.04256,  
    from: 0,
    searchType: "ProductsSearch"
  //{or: [
  //     {
  //       prodFullName: {
  //         matchPhrase: searchText
  //       }
  //     },
  //     {
  //       brandLine: {
  //         matchPhrase: searchText
  //       }
  //     }
  //   ]
  // }
  });
  const resProducts = await API.graphql({
    ...graphqlQueryProduct,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const data = resProducts.data.searchPriceNAvailabilityQueries;
  // const products = resProducts.data.searchProductsMaster.FullName ;
  // const brands = resProducts.data.searchProductsMaster.Brand ;
  // const items = resProducts.data.searchProductsMaster.items
//   if(resProducts.data.searchProductsMaster){
//   resProducts.data.searchProductsMaster.items.forEach((eachResult) => {
//     products.push(new ProductGroup(eachResult));
//   });
// }


// Iterate through array B
// data.FullName.forEach((objB) => {
//   const { key } = objB;
//   const matchingObjC = data.items.find((objC) => objC.prodFullName === key); // Find the first match in array C based on b1 value
//   if (matchingObjC) {
//     objB.prodCategory = matchingObjC.prodCategory; // Attach the corresponding c2 value to a new attribute b3 in array B
//   }
// });

// console.log('data.b',data);

  return {
    // products,
    // categories,
    // brands
    data
  };
}

async function getProductDetails(productId) {
  const SearchableProductFilterInput = {
    filter: {
      or: [{ id: { eq: productId } }],
    },
  };

  const graphqlQuery = graphqlOperation(searchProducts, {
    ...SearchableProductFilterInput,
  });
  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const product = new Product(res.data.searchProducts.items[0]);
  return product;
}

async function getAvailableProducts(prodFullName,distance,lon,lat, category) {
  const SearchableProductFilterInput = {
    filter: {
      prodFullName: {eq: prodFullName}
      }
    }
  // const graphqlQueryProduct = graphqlOperation(searchProductsMaster, {
  //   ...SearchableProductFilterInput,
  // });
  const graphqlQueryProduct = graphqlOperation(searchProductsCatalogue, {
    ...SearchableProductFilterInput,
  });
  const resProducts = await API.graphql({
    ...graphqlQueryProduct,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });

  // console.log('resss',resProducts?.data?.searchProductsMaster?.items);
  const productArr=[];
  for (let i in resProducts?.data?.searchProductsCatalogue?.items){
    productArr.push(resProducts?.data?.searchProductsCatalogue?.items[i].id);
  }

  console.log('productArr::::',productArr);

  const searchStoresByProductIdQuery = graphqlOperation(
    searchPriceNAvailabilitys,
    {
      distance,
      lat,
      lon,
      id: productArr ,
      // showAll: (showAll !== undefined) ? showAll : true
    },
  );
  const res1 = await API.graphql({
    ...searchStoresByProductIdQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  
  const firstArray = resProducts?.data?.searchProductsCatalogue?.items
  .map(obj1=>{
    if (obj1?.packUnits){
    let matches = obj1.packUnits.replace(/[^0-9]/g, "");
    obj1.storeItemQty = matches
    }
    return obj1
  })
  console.log('firstArray1',firstArray)
  // const secondArray = res1?.data?.searchPriceNAvailabilitys?.items;
  // secondArray.map((obj)=>{
  const secondArray = res1?.data?.searchPriceNAvailabilitys?.items
  .filter((item) => item.price>0 && item?.sizeUom !== "0" && item?.storeItemQty != undefined)
  .map(obj => {
    obj.storeSizeUom = obj.sizeUom;
    obj.storeDisplayQty = obj.storeItemQty > 1? `${obj.storeItemQty} Pack` : ''
    let size = '';
    let uom = '';
  
    if (obj.sizeUom !== null) {
      obj.sizeUom = obj.sizeUom.toUpperCase()
      const numbersAndLetters = obj.sizeUom.replace(/\s/g, '');
      const numbers = numbersAndLetters.match(/[0-9.]/g);
      const letters = numbersAndLetters.match(/[a-zA-Z]+/g);
  
      if (numbers !== null) {
        
        size = numbers.join('');
      }
  
      if (letters !== null) {
        uom = letters.join('').toUpperCase();
      }

       // Modify storeSizeUom based on the extracted size and uom i.e., changing L,LT to LTR
       if (uom === 'L' || uom === 'LT') {
        obj.storeSizeUom = `${size}LTR`;
      } else {
        obj.storeSizeUom = `${size}${uom}`;
      }
    }
  
    return { ...obj, size, uom };
  });

  // const mappedArray = secondArray
  //  .filter((obj) => obj.availableQty > 0) // Filter out objects with price > 0
  //  .map((obj2) => {
  //    const matchingObj = firstArray.find((obj1) => obj1.id === obj2.id); // Find matching object in array1
  //    if (matchingObj) {
  //      return { ...obj2, ...matchingObj }
  //     }
  //     return obj2; 
  //   });

// Mapping attributes from array1 to array2

// const mappedArray = [];

// const mappedArray = secondArray
//   .filter((obj) => obj.availableQty > 0) // Filter out objects with price > 0
//   .map((obj2) => {
//     const matchingObj = firstArray.find((obj1) => obj1.id === obj2.id);
// const mappedArray = secondArray
//   .filter(obj2 => obj2.availableQty > 0)
//   .map(obj2 => {
//   const obj1 = firstArray.find(obj => obj.id === obj2.id);

//   if (obj1) {
//     // const size = obj2.size || obj1.size;
//     // const uom = obj2.uom ? obj2.uom.toUpperCase() : (obj1.uom ? obj1.uom.toUpperCase() : null);
//     // const sizeUom = obj2.sizeUom ? obj2.sizeUom.toUpperCase() : (obj1.sizeUom ? obj1.sizeUom.toUpperCase() : null);
//     const size = obj2.size !== '' ? obj2.size : obj1.size;
//     const uom = obj2.uom !== '' ? obj2.uom.toUpperCase() : obj1.uom.toUpperCase();
//     const sizeUom = obj2.sizeUom !== null ? obj2.sizeUom.toUpperCase() : obj1.sizeUom !== null ? obj1.sizeUom.toUpperCase() : null;
//     const sizeUom1 = obj1.sizeUom;
//     // const mergedObject = { ...obj2, ...obj1, size, uom, sizeUom, sizeUom1 };
//     // if (!idSet.has(mergedObject.id)) {
//     //   idSet.add(mergedObject.id);
//     //   mappedArray.push(mergedObject);
//     // }
//     console.log('size',size,uom);
//     return { ...obj2, size, uom, sizeUom,sizeUom1,...obj1 };
//   }
//   return { ...obj2 };
//   // else {
//   //   mappedArray.push(obj2);
//   // }
// });


const uniqueIds = [...new Set([...secondArray.map(obj => obj.id), ...firstArray.map(obj => obj.id)])];

// Mapping the attributes from array1 and array2
// Mapping the attributes from array1 and array2
const newArray = uniqueIds.flatMap(id => {
  const matchingObjs1 = firstArray.filter(obj => obj.id === id);
  const matchingObjs2 = secondArray.filter(obj => obj.id === id);

  return matchingObjs1.flatMap(obj1 => {
    return matchingObjs2.map(obj2 => {
      const mergedAttributes = { ...obj1, ...obj2 };

      for (const attr in mergedAttributes) {
        if (mergedAttributes[attr] === null || mergedAttributes[attr] === '') {
          mergedAttributes[attr] = obj1[attr];
        }
      }

      return mergedAttributes;
    });
  });
});


console.log('newArray',newArray);

console.log('firstArray',secondArray);


if(newArray.length===0)
  {
    return {}
  }
if (newArray.length>0){
    for (let i in newArray){
      // console.log('Beer',newArray[i].uom)
      if (newArray[i].uom === 'OZ'){
        newArray[i].uom = 'FL OZ';
        // console.log('Beer',newArray[i].uom)
      }
      if (newArray[i].uom === 'L'){
        newArray[i].uom = 'LTR';
        // console.log('Beer',newArray[i].uom)
      }
      if (newArray[i].uom === 'LT'){
        newArray[i].uom = 'LTR';
        // console.log('Beer',newArray[i].uom)
      }
      
    }

 }

// Preselected attributes to form the unique combination
let preselectedAttributes;
if (category === 'Beer'){ 
preselectedAttributes = ['storeItemQty','size','uom','containerType'  ];
}

if (category === 'Liquor'){ 
  preselectedAttributes = ['size','uom', 'storeItemQty'
];
  }

if (category === 'Wine'){ 
  preselectedAttributes = ['size', 'uom','storeItemQty', 'country','appellation', 'vintage'];
  }
// Create a new array with separate objects for grouping
const groupedArray = Object.values(newArray.reduce((acc, obj) => {
  const key = preselectedAttributes.map(attr => obj[attr]).join('-');

  if (!acc[key]) {
    acc[key] = {
      attributes: {},
      remainingAttributes: [] // Create a new array for the remaining attributes
    };

    // Copy the preselected attributes to the attributes object
    preselectedAttributes.forEach(attr => {
      acc[key].attributes[attr] = obj[attr];
    });
  }

  // Create a new object containing the remaining attributes
  const newObj = {};
  for (const attr in obj) {
    if (!preselectedAttributes.includes(attr)) {
      newObj[attr] = obj[attr];
    }
  }

  acc[key].remainingAttributes.push(newObj); // Push the remaining attributes to the array
  return acc;
}, {}));

console.log("GroupedArray:::",groupedArray);

// Reduce array 'B' inside each object in 'A'
const reducedA = groupedArray.map((obj) => ({
  attributes: obj.attributes,
  remainingAttributes: obj.remainingAttributes.reduce((acc, item) => {
    const existingItem = acc.find((el) => el.storeId === item.storeId && el.storeItemId === item.storeItemId);
    if (!existingItem || item.imageFile !== null) {
      acc = acc.filter((el) => !(el.storeId === item.storeId && el.storeItemId === item.storeItemId));
      acc.push(item);
    }
    return acc;
  }, []),
}));

console.log('reducedA',reducedA,reducedA[0].attributes[ preselectedAttributes[0] ]);

const reducedArr = reducedA.map((obj) => ({
  attributes: obj.attributes,
  remainingAttributes: obj.remainingAttributes.reduce((acc, item) => {
    const existingItem = acc.find((el) => el.storeId === item.storeId);
    if (!existingItem || item.price < existingItem.price) {
      acc = acc.filter((el) => el.storeId !== item.storeId);
      acc.push(item);
    }
    return acc;
  }, []),
}));

const updatedA = reducedArr;

const nonNullValues = updatedA
  .filter(obj => obj.remainingAttributes[0].imageFile !== null)
  .map(obj => obj.remainingAttributes[0].imageFile);

//   updatedA.forEach(obj => {
//   if (obj.remainingAttributes[0].imageFile === null) {
//     const randomIndex = Math.floor(Math.random() * nonNullValues.length);
//     obj.remainingAttributes[0].imageFile = nonNullValues[randomIndex];
//   }
// });

// Create an object 'nonNullValues' with a3 as key and array of corresponding f values
// const nonNullValues = {};
// updatedA.forEach(obj => {
//   const a3 = obj.attributes[preselectedAttributes[2]];
//   const f = obj.remainingAttributes[0].imageFile;
//   if (f !== null) {
//     if (nonNullValues[a3] === undefined) {
//       nonNullValues[a3] = [];
//     }
//     nonNullValues[a3].push(f);
//   }
// });
// console.log('nonNullValues',nonNullValues);
// // Assign random non-null value from nonNullValues to 'f' if it is null and 'a3' matches
// updatedA.forEach(obj => {
//   if (obj.remainingAttributes[0].imageFile === null && obj.attributes[preselectedAttributes[2]]) {
//     const a3 = obj.attributes[preselectedAttributes[2]];
//     const matchingValues = nonNullValues[a3];
//     if (matchingValues && matchingValues.length > 0) {
//       const filteredValues = matchingValues.filter(val => val !== null);
//       if (filteredValues.length > 0) {
//         const randomIndex = Math.floor(Math.random() * filteredValues.length);
//         obj.remainingAttributes[0].imageFile = filteredValues[randomIndex];
//       }
//     }
//   }
// });

console.log('updatedA',updatedA);

const preselectedValues = preselectedAttributes.reduce((values, attr) => {
  values[attr] = [...new Set(newArray.map(obj => obj[attr]))];
  return values;
}, {});

console.log('preselectedValues',preselectedValues);

  const products = [];
  if(updatedA.length>0){
    updatedA.forEach((eachResult) => {
    products.push( new Product(eachResult));
  });
  console.log("Products::::",products);
  }
  else {
    products.push( new Product(firstArray[0]));
  }
return {
  products,
  preselectedValues,
  preselectedAttributes
};

}

async function getProductCategory(prodFullName){

  const graphqlQueryProduct = graphqlOperation(searchProductsGroups, {
    
    filter: {prodFullName: {eq: prodFullName}},
    limit: 1
  });
  const resProducts = await API.graphql({
    ...graphqlQueryProduct,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const category = resProducts.data.searchProductsGroups.items[0].prodCategory;
  return category;
}  


async function getProductDetailsList(productIdList) {
  // sample or: [{id: {eq: "UE_90034558"}},{id: {eq: "UE_87167016"}}],

  const SearchableProductFilterInput = {
    filter: {},
  };
  SearchableProductFilterInput.filter.or = [];

  productIdList.forEach((eachProductId) => {
    SearchableProductFilterInput.filter.or.push({
      prodFullName: { eq: eachProductId },
    });
  });

  const graphqlQuery = graphqlOperation(searchProductsGroups, {
    ...SearchableProductFilterInput,
  });
  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });
  const products = [];
  res.data.searchProductsGroups.items.forEach((eachResult) => {
    products.push(new Product(eachResult));
  });
  return {
    products,
  };
}

async function getProductsCategoryFilters(categoryName, parentCategoryName) {
  const SearchableProductFilterInput = {
    filter: {
      and: [
        { prodCategory: { eq: parentCategoryName } },
        { prodName: { matchPhrase: categoryName } },
      ],
    },
  };

  const graphqlQuery = graphqlOperation(searchProducts, {
    ...SearchableProductFilterInput,
  });

  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });

  const products = [];
  let filterList = [];
  res.data.searchProducts.items.forEach((eachResult) => {
    products.push(new Product(eachResult));
  });
  let i = 1;
  Object.entries(res.data?.searchProducts).forEach(([key, value], index) => {
    if (value && key !== 'items' && key !== 'nextToken') {
      filterList.push({
        id: i++,
        name: key,
        key: key,
        value: value,
      });
    }
  });

  return filterList;
}

async function getProductsByCategory(
  categoryName,
  parentCategoryName,
  nextToken,
) {
  const SearchableProductFilterInput = {
    filter: {
      and: [
        { prodCategory: { eq: parentCategoryName } },
        { prodName: { matchPhrase: categoryName } },
      ],
    },
  };

  const graphqlQuery = nextToken
    ? graphqlOperation(searchProducts, {
      ...SearchableProductFilterInput,
      limit: 20,
      nextToken,
    })
    : graphqlOperation(searchProducts, {
      ...SearchableProductFilterInput,
      limit: 20,
    });

  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });

  const products = [];
  nextToken = res?.data?.searchProducts?.nextToken;
  res.data.searchProducts.items.forEach((eachResult) => {
    products.push(new Product(eachResult));
  });

  return {
    products,
    nextToken,
  };
}

async function getProductsByCategoryFilter(
  productCategory,
  parentCategoryName,
  selectedFilterList,
  nextToken,
) {
  // const nextToken = null;
  const filterValue = [];

  selectedFilterList.map((filter) => {
    let filterKey = filter.key;
    if (filterKey === 'brand') {
      filterKey = 'brandLine';
    }
    filter.value.map((v) => {
      filterValue.push({ [filterKey]: { eq: v } });
    });
  });
  const SearchableProductFilterInput = {
    filter: {
      // and: { prodCategory: { eq: productCategory } },
      or: [
        ...filterValue,
        { prodCategory: { eq: productCategory } },
        { prodName: { matchPhrase: parentCategoryName } },
      ],
    },
  };

  if (filterValue && filterValue.length > 0) {
    SearchableProductFilterInput.filter.or = [...filterValue];
  }

  const graphqlQuery = nextToken
    ? graphqlOperation(searchProductsByCategory, {
      ...SearchableProductFilterInput,
      limit: 20,
      nextToken,
    })
    : graphqlOperation(searchProductsByCategory, {
      ...SearchableProductFilterInput,
      limit: 20,
    });
  const res = await API.graphql({
    ...graphqlQuery,
    authMode: 'API_KEY',
    authToken: awsExports.aws_appsync_apiKey,
  });

  const products = [];
  nextToken = res?.data?.searchProducts?.nextToken;
  res.data.searchProducts.items.forEach((eachResult) => {
    products.push(new Product(eachResult));
  });

  return {
    products,
    nextToken,
  };
}

async function getAvailableStoresByProductId(product, distance, lat, lon, showAll,state) {
  // Product Available Store
  // const searchStoresByProductIdQuery = graphqlOperation(
  //   searchStoresByProductId,
  //   {
  //     distance,
  //     lat,
  //     lon,
  //     filter: { id: { eq: productId } },
  //     showAll: (showAll !== undefined) ? showAll : true
  //   },
  // );
  // const res = await API.graphql({
  //   ...searchStoresByProductIdQuery,
  //   authMode: 'API_KEY',
  //   authToken: awsExports.aws_appsync_apiKey,
  // });
  const lstSearchedStores = product?.remainingAttributes;
  // // Search Stores For Store Names
  if (lstSearchedStores?.length > 0) {
    console.log('lstSearchedStores',product)

    const storeIds = lstSearchedStores.map((s) => s.storeId);
    // lstSearchedStores.attributes = product?.attributes
    const searchableStoreFilterInput = {
      distance,
      lat,
      lon,
      filter: {},
      showAll: (showAll !== undefined) ? showAll : true
    };
    searchableStoreFilterInput.filter.or = [];

    storeIds.forEach((eachStoreId) => {
      searchableStoreFilterInput.filter.or.push({
        id: { eq: eachStoreId },
        // isDeliveryPaused: { ne: true },
        isOnboarded: { ne: false },
      });
    });
  

    const graphqlQuery = graphqlOperation(searchStore, {
      ...searchableStoreFilterInput,
    });
    const res1 = await API.graphql({
      ...graphqlQuery,
      authMode: 'API_KEY',
      authToken: awsExports.aws_appsync_apiKey,
    });
    const lstStoreDetails = res1?.data?.searchStore?.items;
    console.log('lstStoreDetails',lstStoreDetails);
    const stores = [];
    let deliveryType;
    lstSearchedStores.forEach((searchedStore) => {
      searchedStore.attributes = product?.attributes
      lstStoreDetails.forEach((storeDetailsObj) => {
        if (searchedStore.storeId === storeDetailsObj.id ) {
          console.log('lstStoreDetails1',storeDetailsObj,searchedStore);
    if(storeDetailsObj?.isPickupOutOfState && (storeDetailsObj?.address?.state?.trim()!==state?.trim() || storeDetailsObj?.address?.state?.trim()!==state?.trim())){
      deliveryType= 'Pickup In Store'
    }
    else{

    

          if(!storeDetailsObj?.isDeliveryPaused){
            deliveryType= "Delivery"
          }
          else if(!storeDetailsObj?.isPickupPaused && storeDetailsObj?.orderFulfilling?.pickUp.length>1){
            if(storeDetailsObj?.orderFulfilling?.pickUp[1]=='Pickup_instore'){
              deliveryType= 'Pickup In Store'
            }
            else
            deliveryType = 'Pickup Curbside'
          }
        }
          stores.push({
            ...searchedStore,
            storeName: storeDetailsObj?.storeName,
            deliveryFee : storeDetailsObj?.deliveryFeeConfig ? storeDetailsObj?.deliveryFeeConfig[0]?.deliveryFee: 4.49,
            MinOrderSize : storeDetailsObj?.deliveryScope ? storeDetailsObj?.deliveryScope.MinOrderSize:30,
            merchantFeeToCustomer : storeDetailsObj?.merchantFeeToCustomer?storeDetailsObj?.merchantFeeToCustomer:0,
            deliveryHours : storeDetailsObj?.deliveryHours,
            scheduleHours : storeDetailsObj?.scheduleHours,
            orderFulfilling : storeDetailsObj?.orderFulfilling,
            businessHours : storeDetailsObj?.businessHours,
            deliveryPaused: storeDetailsObj?.isDeliveryPaused,
            pickupPaused: storeDetailsObj?.isPickupPaused,
            automaticDiscountConfig: storeDetailsObj?.automaticDiscountConfig,
            isPickupOutOfState:storeDetailsObj?.isPickupOutOfState,
            automaticDiscountFlag: storeDetailsObj?.automaticDiscountFlag,
            deliveryType,
            deliveryScope:storeDetailsObj?.deliveryScope,
            address:storeDetailsObj?.address,
            storeItemQty:product?.storeItemQty
            
          });
        }
      });
    });
    console.log('storesss',stores);
    return stores;
  }
  return [];
}

export {
  search,
  searchGroups,
  searchPnA,
  getProductsByCategory,
  getProductDetails,
  getAvailableProducts,
  getProductCategory,
  getProductDetailsList,
  getProductsByCategoryFilter,
  getAvailableStoresByProductId,
  getProductsCategoryFilters,
};